import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import { withApolloClient } from "./ApolloClient";
import BottomScrollListener from "react-bottom-scroll-listener";
import { loader } from "graphql.macro";
import { gqlErrorHandler, chkImageUrl } from "./Library";
import { Query } from "@apollo/client/react/components";
import { Dropdown } from "antd";
import Modal from './CustomModal';
import axios from "axios";

const formatter = new Intl.NumberFormat();

const BOOKMARK_LIST_QUERY = loader("../gql/bookmark_list.gql");
const MEMBER_AGREE_MUTATION = loader("../gql/set_push_shop_setting.gql");

class Bookmark extends React.Component {
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	handleFetchMore = (fm, data) => {
		if (data.gongBookmarkList.pageInfo.hasNextPage === false) return;
		fm({
			variables: {
				gongId: process.env.REACT_APP_SERVICE_GID,
				cursor: data.gongBookmarkList.pageInfo.endCursor,
			},
			updateQuery: (previousResult, { fetchMoreResult }) => {
				const newEdges = fetchMoreResult.gongBookmarkList.edges;
				const pageInfo = fetchMoreResult.gongBookmarkList.pageInfo;
				return newEdges.length
					? {
						gongBookmarkList: {
							__typename: previousResult.gongBookmarkList.__typename,
							edges: [...previousResult.gongBookmarkList.edges, ...newEdges],
							pageInfo,
						},
					}
					: previousResult;
			},
		});
	};

	render() {
		return (
			<div id="reWrap">
				<div id="header" className="subHeader">
					<div className="header">
						<NavLink to="/mypage" className="txtHide btnHback">뒤로가기 </NavLink>
						<h1>찜</h1>
					</div>
				</div>
				<div id="container">
					<div id="contents" className="subCnts10">
						<Query query={BOOKMARK_LIST_QUERY} fetchPolicy="network-only" variables={{ gid: process.env.REACT_APP_SERVICE_GID }}>
							{({ loading, error, data, fetchMore }) => {
								if (loading) return null;
								if (error) {
									gqlErrorHandler(error);
									return null;
								}
								const entries = data.gongBookmarkList.edges || [];
								if (entries.length === 0) {
									return (
										<div className="reviewList">
											<ul>
												<li className="noData">
													<p>찜한 상점이 없습니다.<br /><br />마음에 드는 상점 페이지에서<br />하트를 눌러 주세요</p>
												</li>
											</ul>
										</div>
									);
								}
								return (
									<BottomScrollListener onBottom={() => this.handleFetchMore(fetchMore, data)}>
										<div className="bookmarkList">
											<ul>
												{entries.map((v, k) => (
													<BookmarkShop key={k} {...v.node} {...this.props} />
												))}
											</ul>
										</div>
									</BottomScrollListener>
								);
							}}
						</Query>
					</div>
				</div>
			</div>
		);
	}
}

class BookmarkShop extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			shop: {},
			marketingAgree: false
		};
		this.removeBookmarkHandler = this.removeBookmarkHandler.bind(this);
		this.marketingAgreeHandler = this.marketingAgreeHandler.bind(this);
		this.handleToOrder = this.handleToOrder.bind(this);
	}
	componentDidMount() {
		this.setState({
			shop: this.props.shop,
			marketingAgree: this.props.marketingAgree,
		})
	}

	removeBookmarkHandler = (seletedShopId) => {
		Modal.confirm({
			centered: true,
			title: "선택한 상점의 찜하기를 취소하시겠습니까?",
			content: "이벤트 및 혜택 등의 알림을 받아 보실 수 없습니다.",
			onOk: () => {
				axios.post(`${process.env.REACT_APP_SERVER_REST}/v2/api/member/bookmark/del?shop_id=${seletedShopId}`, {},
					{
						headers: {
							Authorization: "Bearer " + localStorage.access_token,
						},
					}
				).then((res) => {
					if (res.data.result) {
						window.location.reload();
					} else {
						Modal.warning({
							content: res.data.msg,
						});
					}
				});
			},
			onCancel: () => { },
		});
	};

	marketingAgreeHandler = (seletedShopId, currentMarketingAgree) => {
		Modal.confirm({
			centered: true,
			title: currentMarketingAgree ? "선택한 상점의 마케팅수신 알림을 해지하겠습니까?" : "선택한 상점의 마케팅수신 알림을 설정하시겠습니까?",
			content: currentMarketingAgree ? "이벤트 및 혜택 등의 알림을 받아 보실 수 없습니다. 찜하기 리스트에	해당상점은 남아있습니다." : "이벤트 및 혜택 등의 알림을 받아 보실 수 있습니다.",
			onOk: () => {
				this.props.client.mutate({
					mutation: MEMBER_AGREE_MUTATION,
					variables: { shopId: seletedShopId, agree: !currentMarketingAgree },
				}).then((data) => {
					if (!data.data.memberAgree.result) {
						gqlErrorHandler(data.data.memberAgree.msg);
					}
					//window.location.reload();
					this.setState({marketingAgree: !currentMarketingAgree })
				}).catch((err) => {
					gqlErrorHandler(err);
				});
			}
		});
	};
	
	handleToOrder = (id, e) => {
		e.preventDefault();
		const tokenStr = localStorage.getItem('access_token') ? `?token=${localStorage.getItem('access_token')}` : ''
		window.open(`${process.env.REACT_APP_SERVER_DOMAIN}/shop/${id}${tokenStr}`)
	}

	render() {
		const {shop, marketingAgree} = this.state;
		return (
			<li key={this.props.k}>
				<div className="img">
					<img src={chkImageUrl(shop.img)} onClick={(e) => this.handleToOrder(shop.pk, e)} alt="" />
				</div>
				<div className="cnt">
					<h4 onClick={(e) => this.handleToOrder(shop.pk, e)}>{shop.name}</h4>
					<Dropdown trigger={["click"]}
						overlay={
							<div className="layerEtc" style={{ display: "block", top: 0, width: 135, }}>
								<a href="#this" onClick={(e) => { e.preventDefault(); this.marketingAgreeHandler(shop.pk, marketingAgree); }}>{marketingAgree ? "마케팅 알림 해지" : "마케팅 알림 설정"}</a>
								<a href="#this" onClick={(e) => { e.preventDefault(); this.removeBookmarkHandler(shop.pk); }}>찜하기 취소</a>
							</div>
						}
					>
						<a href="#this" className="txtHide btnEtc ant-dropdown-link" onClick={(e) => e.preventDefault()}>기타</a>
					</Dropdown>
					<div className="divState" onClick={(e) => this.handleToOrder(shop.pk, e)}>
						{shop.useReservation && (<span className="icoSt">매장</span>)}
						{shop.useTakeout && (<span className="icoTo">포장</span>)}
						{shop.useDelivery && (<span className="icoDe">배달</span>)}
					</div>
					<span className="adr" onClick={(e) => this.handleToOrder(shop.pk, e)}>{shop.addr1}</span>
					<p onClick={(e) => this.handleToOrder(shop.pk, e)}>최소주문{" "}{formatter.format(shop.deliveryMinPrice)} 원 </p>
					{shop.useDelivery && <p onClick={(e) => this.handleToOrder(shop.pk, e)}>배달팁{" "}{formatter.format(shop.shipMinPrice)}{" "}{shop.shipMaxPrice > shop.shipMinPrice && ` ~ ${formatter.format(shop.shipMaxPrice)}`}원</p>}
				</div>
			</li>
		)
	}
}

export default withRouter(withApolloClient(Bookmark));
