import React, { useEffect } from "react";
import { Link, useHistory } from 'react-router-dom'
import $ from 'jquery'
import { useQuery, useReactiveVar } from '@apollo/client';
import { loader } from 'graphql.macro'
import { locAddressMainVar } from './ApolloClient'
import { gqlErrorHandler, chkImageUrl, isLogin, headerShadow } from './Library'
import Swiper from 'swiper'
import 'swiper/css/swiper.css'
import Popup from './Popup';
import Footer from './Footer'
import PushMark from './PushMark';
import { useHeaderAddr } from '../hook/useAddrInfo'
import { gql } from 'graphql.macro'
import Modal from './CustomModal'

const mainBannerGql = loader('../gql/main_banner.gql')
const shopListGql = loader('../gql/shop_list.gql')
const lastOrderGql = loader('../gql/last_order.gql')
const memberCompanyAgreeGql = loader('../gql/member_company_agree.gql')

const formatter = new Intl.NumberFormat()

const Main = () => {
	useEffect(() => {
		window.scrollTo(0, 0)
	})
	/* global AppScheme */
	typeof AppScheme !== "undefined" && AppScheme(`sp00order${process.env.REACT_APP_SERVICE_GID}://clear_history`)

	return (
		<div id="reWrap">
			<Header />
			<div id="container">
				<div id="contents" className="conType02">
					<Banner />
					<Banner2 />
					<Category />
					{isLogin() && <RecentOrder />} {/*최근 주문*/}
					<HasCouponShop /> {/*쿠폰보유상점*/}
					<NewShop /> {/*새로 입점*/}
					<ReviewShop /> {/*후기가 많은 상점*/}
					<ReportArea />
				</div>
			</div>
			<Copyright />
			<Footer />
		</div>
	)
}
const Header = () => {
	let addressTxt = useHeaderAddr()
	useEffect(() => {
		setTimeout(function () {
			$('.layerMyAdr').fadeOut(1000);
		}, 3000);
		headerShadow()
	})
	return (
		<div id="header">
			<div className="header">
				<div className="adr"><Link to='/mypage/set-address'>{addressTxt}</Link></div>
				<div className="layerMyAdr">
					<p>현재 내 <strong>주소</strong>가 맞나요?</p>
				</div>
				<div className="btnHr">
					<PushMark />
					<Link to='/search' className="txtHide btnSch">검색</Link>
				</div>
			</div>
		</div>
	)
}
const Banner = () => {
	const values = { gid: process.env.REACT_APP_SERVICE_GID, }
	const { loading, error, data } = useQuery(mainBannerGql, { variables: values });

	useEffect(() => {
		if (!loading) {
			new Swiper('.bannerVisual .swiper-container', {
				slidesPerView: 'auto',
				loop: true,
				autoplay: {
					delay: 3500,
					disableOnInteraction: false
				},
				pagination: {
					el: ".bannerVisual .swiper-pagination",
					type: "fraction",
				}
			});
		}
	})

	if (loading || !data) return null;
	if (error) { gqlErrorHandler(error); return null; }

	return (
		<div className="bannerVisual">
			<div className="swiper-container">
				<ul className="swiper-wrapper">
					{data.gongBanners.edges.map((item) => (
						<li className="swiper-slide" key={item.node.id}><Popup href={item.node.url} type={item.node.target}><img src={chkImageUrl(item.node.imgUrl)} alt="이미지" /></Popup></li>
					))}
				</ul>
				<Link to='/event' className="swiper-pagination" />
			</div>
		</div>
	)
}
const Banner2 = (props) => {
	const values = { company: "JICA", }
	const { loading, error, data } = useQuery(memberCompanyAgreeGql, { variables: values });

	const handleToPage = () => {
		if (!isLogin()) {
			Modal.alert({
				content: '로그인 후 이용 가능합니다.',
				onOk: () => { window.location = "/login" }
			})
			return
		}
		
		if (data.memberCompanyAgree) {
			/* global AppScheme */
			typeof AppScheme !== "undefined" && AppScheme(`sp00order${process.env.REACT_APP_SERVICE_GID}://outbrowser?url=https://xn--2e0bk4jh4sm2a.kr`)
			if (typeof AppScheme == "undefined") {
				window.location.href = "https://xn--2e0bk4jh4sm2a.kr";
			}
		} else {
			window.open("/agree")
		}
		
	};

	return (
		<div className="bannerVisual">
			<ul>
				<li>
				<a href="#this" onClick={(e) => { e.preventDefault(); handleToPage(); }}><img src="/img/banner2.png" alt="이미지" /></a>
				</li>
			</ul>
		</div>
	)
}
const Category = () => {
	let history = useHistory()
	const categoary = [
		{ cateCode: 36, name: '카페/디저트', className: 'm01' },
		{ cateCode: 37, name: '한식', className: 'm02' },
		{ cateCode: 38, name: '분식', className: 'm03' },
		{ cateCode: 39, name: '일식/회', className: 'm04' },
		{ cateCode: 40, name: '양식/돈까스', className: 'm05' },
		{ cateCode: 41, name: '중식', className: 'm06' },
		{ cateCode: 61, name: '치킨', className: 'm07' },
		{ cateCode: 62, name: '피자', className: 'm15' },
		{ cateCode: 43, name: '족발/보쌈', className: 'm08' },
		{ cateCode: 77, name: '야식/아시안', className: 'm09' },
		//{ cateCode: 44, name: '야식', className: 'm09' },
		{ cateCode: 45, name: '찜/탕', className: 'm10' },
		// { cateCode: 46, name: '도시락', className: 'm11' },
		{ cateCode: 47, name: '버거/도시락', className: 'm12' },
		{ cateCode: 48, name: '청과/정육', className: 'm13' },
		//{ cateCode: 56, name: '아시안', className: 'm14' },
		{ cateCode: 69, name: '가맥', className: 'm18' },
		// { cateCode: 'gamaek', name: '가맥', className: 'm18' },
		{ cateCode: 78, name: '착한가격업소', className: 'm19' },
	]
	const directToCategory = (e, code) => {
		e.preventDefault()
		//localStorage.setItem('directToCateCode', code);
		history.push(`/shop/${code}`)
	}
	return (
		<div className="menuList">
			<div className="box">
				<ul>
					{categoary.map((item, k) => {
						return (
							<li key={k}>
								<div className="mBox">
									<a href="#this" onClick={(e) => directToCategory(e, item.cateCode)} className={item.className}><span>{item.name}</span></a>
								</div>
							</li>
						)
					})}
				</ul>
			</div>
		</div>
	)
}
const RecentOrder = () => {
	const values = {
		gid: process.env.REACT_APP_SERVICE_GID,
	}
	const { error, data } = useQuery(lastOrderGql, { variables: values, fetchPolicy: 'cache-and-network' });
	if (!data) return null; //cache-and-network일때는 loading보다 data로 판단.
	if (error) { gqlErrorHandler(error); return null; }

	return data.gongLastOrders.length > 0
		? <div className="recentOrderList">
			<h3 className="titLink1"><Link to="/order">최근에 주문했어요!</Link></h3>
			<ul>
				{data.gongLastOrders.map((item) => (
					<li key={item.pk}>
						<a href="#this" onClick={(e) => handleToOrder(item.shop.pk, e)}>
							<div className="img"><img src={chkImageUrl(item.shop.img)} alt="" /></div>
							<div className="cnt">
								<h4>{item.shop.name}</h4>
								<span className="reviewNum">리뷰 <em>{formatter.format(item.shop.reviewCount)}</em></span>
								<div className="divState">
									{item.shop.useReservation && <span className="icoSt">매장</span>}
									{item.shop.useTakeout && <span className="icoTo">포장</span>}
									{item.shop.useDelivery && <span className="icoDe">배달</span>}
								</div>
								{item.shop.useDelivery && <p>배달비 {formatter.format(item.shop.shipMinPrice)}원 ~ </p>}
								<p>최소주문 {formatter.format(item.shop.deliveryMinPrice)}원</p>
							</div>
						</a>
					</li>
				))}
			</ul>
		</div>
		: null
}
function HasCouponShop() {
	const locAddressMain = useReactiveVar(locAddressMainVar)
	let values = {
		gid: process.env.REACT_APP_SERVICE_GID,
		first: 5,
		km: 20,
		sort: 'near',
		isOpen: true,
		hasCoupon: true,
	}
	values.geo = locAddressMain ? `${locAddressMain.lng},${locAddressMain.lat}` : '127.148043825856,35.8241084231658'
	values.addrCode = locAddressMain ? `${locAddressMain.hCode},${locAddressMain.bCode}` : '4511160500,4511114700'

	const { loading, error, data } = useQuery(shopListGql, { variables: values });

	useEffect(() => {
		if (!loading) {
			new Swiper('#hasCouponShop.storeList .swiper-container', {
				slidesPerView: 'auto'
			});
		}
	})

	if (loading) return null;
	if (error) { gqlErrorHandler(error); return null; }

	return data.gongShops.edges.length > 0
		? <div className="storeList" id="hasCouponShop">
			<h3 className="titLink1"><Link to="/shop?cp=y">쿠폰이 있어요!</Link></h3>
			<div className="swiper-container">
				<ul className="swiper-wrapper">
					{data.gongShops.edges.map((item) => (
						<MainShopUnit shop={item.node.shop} key={`hasCouponShop${item.node.shop.pk}`} />
					))}
				</ul>
			</div>
		</div>
		: null
}
const NewShop = () => {
	const locAddressMain = useReactiveVar(locAddressMainVar)
	let values = {
		gid: process.env.REACT_APP_SERVICE_GID,
		first: 5,
		km: 20,
		sort: 'new',
		isOpen: true,
	}
	values.geo = locAddressMain ? `${locAddressMain.lng},${locAddressMain.lat}` : '127.148043825856,35.8241084231658'
	values.addrCode = locAddressMain ? `${locAddressMain.hCode},${locAddressMain.bCode}` : '4511160500,4511114700'

	const { loading, error, data } = useQuery(shopListGql, { variables: values });

	useEffect(() => {
		if (!loading) {
			new Swiper('#newshop.storeList .swiper-container', {
				slidesPerView: 'auto'
			});
		}
	})

	if (loading) return null;
	if (error) { gqlErrorHandler(error); return null; }

	return (
		<div className="storeList bgGray" id="newshop">
			<h3 className="titLink1"><Link to="/shop" onClick={() => localStorage.setItem("shopListSorty", "new")}>따끈따끈! 새로 입점한 상점</Link></h3>
			<div className="swiper-container">
				<ul className="swiper-wrapper">
					{data.gongShops.edges.map((item) => (
						<MainShopUnit shop={item.node.shop} key={`new${item.node.shop.pk}`} />
					))}
				</ul>
			</div>
		</div>
	)
}
const ReviewShop = () => {
	const locAddressMain = useReactiveVar(locAddressMainVar)
	let values = {
		gid: process.env.REACT_APP_SERVICE_GID,
		first: 5,
		km: 20,
		sort: 'review',
		isOpen: true,
	}
	values.geo = locAddressMain ? `${locAddressMain.lng},${locAddressMain.lat}` : '127.148043825856,35.8241084231658'
	values.addrCode = locAddressMain ? `${locAddressMain.hCode},${locAddressMain.bCode}` : '4511160500,4511114700'

	const { loading, error, data } = useQuery(shopListGql, { variables: values });

	useEffect(() => {
		if (!loading) {
			new Swiper('#reviewshop.storeList .swiper-container', {
				slidesPerView: 'auto'
			});
		}
	})

	if (loading || !data) return null;
	if (error) { gqlErrorHandler(error); return null; }

	return (
		<div className="storeList" id="reviewshop">
			<h3 className="titLink1"><Link to="/shop" onClick={() => localStorage.setItem("shopListSorty", "review")}>후기가 많은 상점이에요!</Link></h3>
			<div className="swiper-container">
				<ul className="swiper-wrapper">
					{data.gongShops.edges.map((item) => (
						<MainShopUnit shop={item.node.shop} key={`review${item.node.shop.pk}`} />
					))}
				</ul>
			</div>
		</div>
	)
}
const MainShopUnit = (props) => {
	return (
		<li className="swiper-slide">
			<a href="#this" onClick={(e) => handleToOrder(props.shop.pk, e)}>
				<div className="img"><img src={chkImageUrl(props.shop.img)} alt="" /></div>
				<div className="cnt">
					<h4>{props.shop.name}</h4>
					<div className="divState">
						{props.shop.useReservation && <span className="icoSt">매장</span>}
						{props.shop.useTakeout && <span className="icoTo">포장</span>}
						{props.shop.useDelivery && <span className="icoDe">배달</span>}
					</div>
				</div>
			</a>
		</li>
	)
}
function ReportArea() {
	const getFirstNoticeGql = gql`
		query getFirstNotice($gid: Float) {
			gongNotices(id: $gid, category: "NOTICE", first: 1) {
				edges {
					node {
						pk
						category
						categoryName
						title
						createdAt
					}
				}
			}
		}
	`
	const values = { gid: process.env.REACT_APP_SERVICE_GID }
	const { loading, error, data } = useQuery(getFirstNoticeGql, { variables: values });

	if (loading) return null;
	if (error) { gqlErrorHandler(error); return null; }

	return (
		<ul className="promoteList">
			{data?.gongNotices.edges.length > 0 &&
				<li className="promoteNotice">
					<span>공지사항</span>
					<Link to={`/mypage/notice/view/${data.gongNotices.edges[0].node.pk}`}>{data.gongNotices.edges[0].node.title}</Link>
				</li>
			}
			<li>
				<span>이벤트</span>
				<Popup href="https://litt.ly/jj_order">전주맛배달 이벤트 바로가기</Popup>
			</li>
			<li>
				<span>블로그</span>
				<Popup href="https://blog.naver.com/jj_order">네이버 블로그 바로가기</Popup>
			</li>
			<li>
				<span>SNS</span>
				<Popup href="https://www.instagram.com/jj_order/">인스타그램 바로가기</Popup>
			</li>
		</ul>
	)
}
const Copyright = () => {
	useEffect(() => {
		$('.btnCmpInfo').off('click').on('click', function (e) {
			e.preventDefault();
			var _this = $('.btnCmpInfo');
			if (_this.hasClass('on')) {
				$(this).removeClass('on');
				$('.offDiv').show();
				$('.onDiv').hide();
			} else {
				$(this).addClass('on');
				$('.offDiv').hide();
				$('.onDiv').show();
				$('#wrap').scrollTop($('#wrap').scrollTop() + 54)
			}
		});
	})
	return (
		<div id="footer">
			<div className="footer">
				<a href="#this" className="btnCmpInfo">사업자 정보 확인</a>
				<div className="offDiv">
					<p className="cmpDesc">
						㈜커넥트웨이브는 통신판매중개자이며 통신판매의 당사자가 아닙니다.<br />
						따라서 (주)커넥트웨이브는 상품거래정보 및<br />
						거래에 대한 책임을 지지 않습니다.
					</p>
					<a href="tel:1899-1010" className="btnTel">전주맛배달 고객센터 : 1899-1010</a>
				</div>
				<div className="onDiv">
					<ul>
						<li>㈜커넥트웨이브</li>
						<li>대표 : 이건수</li>
						<li>사업자 번호 : 117-81-40065</li>
						<li>주소 : 서울특별시 금천구 벚꽃로 298, 17층(가산동, 대륭포스트타워6차)</li>
					</ul>
					<div className="guideTerm">
						<Popup href="https://makeshop.notion.site/_-85989047544f427ba37660c10d6facc7">이용약관</Popup>
						<Popup href="https://makeshop.notion.site/_-2869f0a7cfda4bb8aad99b2e46de4e32">개인정보처리방침</Popup>
						<Popup href="https://makeshop.notion.site/_-69f04fa78ac14f6d99ea42d45aba8b3c">위치기반 서비스 이용약관</Popup>
					</div>
					<p className="cmpDesc">
						㈜커넥트웨이브는 통신판매중개자이며 통신판매의 당사자가 아닙니다.<br />
						따라서 커넥트웨이브는 상품거래정보 및 거래에 대한 책임을 지지 않습니다.
					</p>
					<a href="tel:1899-1010" className="btnTel">전주맛배달 고객센터 : 1899-1010</a>
				</div>
			</div>
		</div>
	)
}

const handleToOrder = (id, e) => {
	e.preventDefault();
	const tokenStr = localStorage.getItem('access_token') ? `?token=${localStorage.getItem('access_token')}` : ''
	window.open(`${process.env.REACT_APP_SERVER_DOMAIN}/shop/${id}${tokenStr}`)
}
export default Main
