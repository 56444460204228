
import React from 'react';
import { ApolloClient, InMemoryCache, ApolloConsumer, from, makeVar } from '@apollo/client';
import { setContext } from '@apollo/client/link/context'
import { createUploadLink } from 'apollo-upload-client';

const uploadLink = createUploadLink({ uri: process.env.REACT_APP_SERVER_GQL, credentials: 'include' })

const authLink = setContext((_, { headers }) => {
	// get the authentication token from local storage if it exists
	const token = localStorage.getItem('access_token');
	// return the headers to the context so httpLink can read them
	return {
		headers: {
			...headers,
			authorization: token ? `Bearer ${token}` : "",
		}
	}
});

const link = from([
	authLink,
	uploadLink,
])

export default new ApolloClient({
	cache: new InMemoryCache({
		typePolicies: {
			Query: {
				fields: {
					locAddressMain: {
						read() {
							return locAddressMainVar()
						}
					}
				}
			}
		}
	}),
	link,
});

/* locAddressMain값이 없으면 null이고, null 혹은 "null"일 때 모두 null로 parse됨. */
export const locAddressMainVar = makeVar(JSON.parse(localStorage.getItem("locAddressMain")))

export const withApolloClient = InnerComponent => class extends React.Component {
	render() {
		return (
			<ApolloConsumer>
				{client => (
					<InnerComponent
						{...this.props}
						client={client}
					/>
				)}
			</ApolloConsumer>
		)
	}
}