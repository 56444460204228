import React from "react";
import { NavLink, withRouter } from "react-router-dom";

class PermissionInfo extends React.Component {
	render() {
		return (
			<div id="wrap-sub" className="setting">
				<div className="white-cont">
					<div className="txt-wrap">
						<strong><span className="c-red">어디go</span>에서<br />
						고객님들께 알려드립니다.</strong>
						<p>정보통신망법령에 맞춰 서비스 이용에<br />꼭 필요한 항목만 접근하고 있습니다.</p>
					</div>
					<ul>
						<li>
							<strong>전화 <span>(선택)</span></strong>
							<p>푸쉬메시지 발송을 위함</p>
						</li>
						<li>
							<strong>위치 정보 권한 <span>(선택)</span></strong>
							<p>내 근처 상점 확인을 위함</p>
						</li>
					</ul>
					<p className="s-txt">선택적 접근 권한은 해당 기능을 사용할 때 허용이 필요하며, 비 허용시에도 해당 기능 외 서비스 이용이 가능합니다.</p>
					<p className="s-txt">[접근권한 변경 밥법]<br /><span className="c-red">휴대폰 설정 &gt; 애플리케이션 관리 &gt; 어디go</span>에서 변경</p>
					<div className="btn-bottom-fixed"><NavLink to="/mypage/setting"><strong className="c-red btn-setting">확인하였습니다</strong></NavLink></div>
				</div>
			</div>
		)
	}
}

export default withRouter(PermissionInfo)