import React from "react";
import { Route, Redirect } from "react-router-dom";
import { locAddressMainVar } from './ApolloClient'
import { isLogin } from './Library'

export default ({ children, isPrivate, notChkAddr, ...rest }) => {
	const permAddr = localStorage.getItem("permAddr");
	return (
		<Route
			{...rest}
			render={
				({ location }) => {
					if (!locAddressMainVar() && !notChkAddr) {/* 주소미설정시주소설정으로보냄, reactive필요없음. */
						const pathname = permAddr === 'Y' ? "/mypage/set-address" : "/permission-addr"
						return <Redirect to={{ pathname }} />
					}
					if (!isLogin() && isPrivate) {/* 로그인필요 컴포넌트 */
						/* prevpath는 기존소스 호환용 */
						localStorage.prevpath = window.location.pathname;
						return (
							<Redirect
								to={{
									pathname: "/login",
									state: { from: location } /* 이걸로 전페이지 조회하도록 */
								}}
							/>
						)
					}
					return children
				}
			}
		/>
	);
}