import React from "react";
import { Spin } from 'antd'
import Modal from './CustomModal'
import { LoadingOutlined } from '@ant-design/icons'
import { withRouter } from "react-router-dom";
import { loader } from 'graphql.macro'
import { withApolloClient } from './ApolloClient';
import { gqlErrorHandler, bodyScrollLock } from './Library'
import _ from 'lodash'
import $ from 'jquery';
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)

const orderListGql = loader('../gql/order_list.gql')
const orderRecartGql = loader('../gql/order_recart.gql')
const formatter = new Intl.NumberFormat()

class OrderDetail extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			order: null,
		}
		this.antIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />
	}
	componentDidMount() {
		const { id } = this.props.match.params
		this.props.client
			.query({
				query: orderListGql,
				variables: { gid: process.env.REACT_APP_SERVICE_GID, pk: id },
			})
			.then((data) => {
				this.setState({ order: data.data.gongOrders.edges[0].node })
			})
			.catch((err) => { gqlErrorHandler(err) });
	}
	componentWillUnmount() {
		bodyScrollLock.unlock()
	}
	componentDidUpdate() {
		bodyScrollLock.lock()
		$('#layerOrderDetail').animate({ opacity: 1 }, 'fast')
	}
	hanbleClose = (e) => {
		e.preventDefault()
		$('#layerOrderDetail').animate({ opacity: 0 }, 'fast', this.props.history.goBack)
	}
	handleRecart = (order, e) => {
		e.preventDefault()
		Modal.confirm({
			title: "해당 주문 내역과 동일하게 주문하시겠습니까?",
			okText: "확인",
			cancelText: "취소",
			onOk: () => {
				const variables = {
					shopId: order.shop.pk,
					token: order.token,
				}
				this.props.client
					.mutate({
						mutation: orderRecartGql,
						variables,
					})
					.then((data) => {
						window.open(`${process.env.REACT_APP_SERVER_DOMAIN}/cart/${order.shop.pk}?token=${localStorage.getItem('access_token')}&ctoken=${data.data.orderRecart.token}`);
						this.props.history.goBack()
					})
					.catch((err) => { gqlErrorHandler(err) })
			},
			onCancel() { },
		});
	}

	render() {
		const order = this.state.order ? { ...this.state.order } : null

		return order === null
			? <div id="overlay" style={{ display: 'grid', placeItems: 'center', backgroundColor: 'transparent' }}>
				<Spin tip="" indicator={this.antIcon} />
			</div>
			: <div id="layerOrderDetail" style={{ opacity: 0 }}>
				<div className="bgModal60" style={{ display: 'block' }}></div>
				<div className="layerBox">
					<div className="layerHd">
						<h3>주문상세</h3>
					</div>
					<div className="layerCnt">
						<div className="orderDetailView">
							<div className="section">
								<div className="tp">
									{order.orderType === 'SHOP' && <span className="icoState3">매장식사</span>}
									{order.orderType === 'TAKEOUT' && <span className="icoState2">픽업</span>}
									{order.orderType === 'DELIVERY' && <span className="icoState1">배달</span>}
									<a href="#this" className="btnReOrder" onClick={(e) => this.handleRecart(order, e)}>재주문</a>
								</div>
								<dl className="nameDate">
									<dt>{order.shop.name}</dt>
									<dd>{dayjs(order.orderAt, 'YYYY.MM.DD HH:mm').format('YYYY-MM-DD HH:mm')}</dd>
									<dd>주문번호: {order.no}</dd>
								</dl>
								<div className="group1">
									<ul>
										{order.items.map(item =>
											<li key={item.pk} className={order.orderStatus === 'CANCEL' ? 'cancle' : ''}>
												<dl>
													<dt>{item.receipt.name} {item.count > 1 && (' X ' + item.count)}</dt>
													<dd>{formatter.format(item.receipt.price * item.count)} 원</dd>
												</dl>
												{item.receipt.options.map(option =>
													option.items.map(ov =>
														<dl className="sub" key={`si${ov.pk}`}>
															<dt>- {ov.name}</dt>
															<dd>{formatter.format(ov.price * item.count)} 원</dd>
														</dl>
													)
												)}
											</li>
										)}
									</ul>
								</div>
								<div className="group2">
									<ul>
										<li>
											<dl>
												<dt>주문금액</dt>
												<dd>{formatter.format(_.sumBy(order.items, 'price'))} 원</dd>
											</dl>
										</li>
										{order.orderType === 'DELIVERY' &&
											<li>
												<dl>
													<dt>배달금액</dt>
													<dd>+ {formatter.format(order.priceShip)} 원</dd>
												</dl>
											</li>
										}
										{order.coupon &&
											<li>
												<dl>
													<dt>쿠폰 할인</dt>
													<dd>- {formatter.format(order.priceDiscount)} 원</dd>
												</dl>
											</li>
										}
									</ul>
								</div>
								<div className="group3">
									<ul>
										<li>
											<dl>
												<dt>총 결제금액</dt>
												<dd>{formatter.format(order.price)} 원</dd>
											</dl>
										</li>
										<li>
											<dl>
												<dt>결제수단</dt>
												<dd>{order.payinfo.payMethodName}</dd>
											</dl>
										</li>
									</ul>
								</div>
							</div>
							<hr className="hrBk" />
							<div className="section">
								{order.commentShop &&
									<div className="group4">
										<h4>매장 요청사항</h4>
										<p style={{ wordBreak: "break-word", whiteSpace: "pre-wrap" }}>
											{order.commentShop}
										</p>
									</div>
								}
								{order.commentShip && order.orderType === 'DELIVERY' &&
									<div className="group4">
										<h4>배달 요청사항</h4>
										<p style={{ wordBreak: "break-word", whiteSpace: "pre-wrap" }}>
											{order.commentShip}
										</p>
									</div>
								}
								{order.orderType === 'DELIVERY' &&
									<div className="group4">
										<h4>배달주소</h4>
										<p>
											{order.shipAddr1} {order.shipAddr2}
										</p>
									</div>
								}
								<div className="group4">
									<h4>수령인 전화번호</h4>
									<p>
										{order.phone.replace(/^(.*)(.{4})(.{4})$/, '$1-$2-$3')}
									</p>
								</div>
								<div className="group4">
									<h4>상점정보</h4>
									<p>
										주소: {order.shop.addr1} {order.shop.addr2}<br />
										사업자번호: {order.shop.bizNum} (대표자: {order.shop.ownerName})<br />
										전화번호: {order.shop.tel.replace(/^(.*)(.{4})(.{4})$/, '$1-$2-$3')}
									</p>
								</div>
								{order.orderStatus === 'CANCEL' &&
									<div className="group4">
										<h4>취소사유</h4>
										<p style={{ wordBreak: "break-word", whiteSpace: "pre-wrap" }}>
											{order.cancelReason}
										</p>
									</div>
								}
							</div>
						</div>
					</div>
					<a href="#this" className="txtHide btnLayerClose1" onClick={this.hanbleClose}>닫기</a>
				</div>
				<div className="blank"></div>
			</div>
	}
}

export default withApolloClient(withRouter(OrderDetail))