import React from "react";
import { useRouteMatch, useHistory, Switch, Route } from "react-router-dom";
import Modal from './CustomModal'
import SearchAddress from './SearchAddress'
import MapAddress from './MapAddress'
import { gqlErrorHandler, setLocAddressMain, isLogin, getLocation } from './Library'
import { useQuery, useMutation, useReactiveVar } from '@apollo/client';
import { loader } from 'graphql.macro'
import { locAddressMainVar } from './ApolloClient'
import { isAndroid } from 'react-device-detect';

const memberAddressGql = loader('../gql/member_address.gql')
const setAddressMainGql = loader('../gql/set_address_main.gql')
const delAddressGql = loader('../gql/del_address.gql')

function SetAddress() {
	const handleLocationToMap = (e, doSuccess) => {
		e.preventDefault()
		getLocation((pos, res) => {/* 현위치 클랙할 때 마다 호출 */
			if (res) {
				const { latitude, longitude } = pos.coords
				localStorage.setItem('geoLatLng', JSON.stringify({ latitude, longitude }))
				doSuccess() /* 현위치접근가능시에만 넘어가기 */
			} else { /* 현재위치접근허용설정유도 */
				let config = {
					title: <><div>현재 위치를 찾을 수 없습니다.</div><div>위치서비스를 켜 주세요.</div></>,
					okText: "도움말",
					cancelText: "취소",
					onOk() { },
					onCancel() { },
				}
				/* global AppScheme */
				if (typeof AppScheme !== "undefined") { /* 앱일때 */
					if (!isAndroid) { /* 안드로이드는 껍데기에서 안내를 띄워줌 */
						config.onOk = () => AppScheme(`sp00order${process.env.REACT_APP_SERVICE_GID}://inbrowser?url=${encodeURIComponent("https://pang.so/letitbe_210917")}`);
						Modal.confirm(config);
					}
				} else { /* 브라우저일때 */
					Modal.info(config);
				}
			}
		})
	}

	const { path, url } = useRouteMatch()
	const history = useHistory()

	return (
		<Switch>
			<Route exact path={path}>
				<div id="reWrap">
					{/* <!-- 상단 --> */}
					<div id="header" className="subHeader4">
						<div className="header">
							{locAddressMainVar() && <div className="btnList" onClick={history.goBack}><a href="#this" className="txtHide" onClick={(e) => { e.preventDefault() }}>뒤로가기</a></div>}
							<h1>내 주소 설정</h1></div>
					</div>
					{/* <!--// 상단 --> */}

					{/* <!-- 컨텐츠 --> */}
					<div id="container">
						<div id="contents" className="subCnts6">
							<div className="myAdrSetup">
								<div className="myAdrSch">
									<h3><strong>주소</strong>를<br />입력해주세요</h3>
									<p>도로명, 지번, 건물명을 입력해 주세요.</p>
									<div className="input" onClick={(e) => { e.preventDefault(); window.open(`${process.env.REACT_APP_SERVICE_URI}/mypage/set-address/search`); }}>
										<input type="text" name="" value="" readOnly placeholder="예) 강남구 삼성동 154-18" />
										<a href="#this" className="txtHide btnAdrSch">검색</a>
									</div>
									<a href="#this" className="btnLocal" onClick={(e) => handleLocationToMap(e, () => history.push(`${url}/map`))}>현재 위치로 주소 설정</a>
								</div>
								<MyAddr />
							</div>
						</div>
					</div>
					{/* <!--// 컨텐츠 --> */}
					{/* <Footer /> */}
				</div>
			</Route>
			<Route path={`${path}/search`}>
				<SearchAddress handleLocationToMap={handleLocationToMap} />
			</Route>
			<Route path={`${path}/map`}>
				<MapAddress />
			</Route>
		</Switch>
	)
}

const MyAddr = () => { // 나의 주소
	const locAddressMain = useReactiveVar(locAddressMainVar)

	return locAddressMain &&
		<>
			<div className="myAdrList">
				<div className="group">
					<h4>최근 주소</h4>
					<ul>
						<li>
							<div className="adr">
								<p>{locAddressMain.addr1} {locAddressMain.addr2}</p>
								<span>지번 : {locAddressMain.oldAddr1} {locAddressMain.oldAddr2}</span>
							</div>
							<span className="typify">현재<br />주소</span>
						</li>
						{isLogin() && <RecentAddrList />}
					</ul>
				</div>
			</div>
		</>
}
const RecentAddrList = () => { //최근주소
	const [delAddress] = useMutation(delAddressGql)
	const [setAddress] = useMutation(setAddressMainGql)

	const handleDeleteRecentAddr = (addr, e) => {
		e.preventDefault()
		Modal.confirm({
			title: "해당 주소를 삭제하시겠습니까?",
			okText: "확인",
			cancelText: "취소",
			onOk: () => { handleDeleteOK(addr) }
		})
	}
	const handleDeleteOK = (addr) => {
		delAddress({ variables: { pk: addr.pk } })
			.then(() => refetch())
			.catch((err) => gqlErrorHandler(err))
	}
	const handleAppleRecentAddr = (addr, e) => {
		e.preventDefault()
		Modal.confirm({
			title: <>해당 주소를 <br />내 주소로 설정하시겠습니까?</>,
			content: <>
				{addr.oldAddr1} {addr.oldAddr2}
				{addr.addr1 && <><br />{addr.addr1} {addr.addr2}</>}
			</>,
			okText: "확인",
			cancelText: "취소",
			onOk: () => { handleApplyOK(addr) }
		})
	}
	const handleApplyOK = (addr) => {
		setAddress({ variables: { pk: addr.pk } })
			.then(() => {
				const locAddr = {
					...addr,
					sido: addr.region.sido,
					gugun: addr.region.gugun,
					dong: addr.region.dong,
				}
				setLocAddressMain(locAddr)
				refetch()
			})
			.catch((err) => gqlErrorHandler(err))
	}
	const values = {
		isMain: false,
		first: 10,
	}
	const { loading, error, data, refetch } = useQuery(memberAddressGql, { variables: values, fetchPolicy: 'cache-and-network' });
	if (loading) return null;
	if (error) { gqlErrorHandler(error); return null; }

	return data.memberAddressList.edges.map((item) => {
		return (
			<li key={item.node.pk}>
				<div className="adr" onClick={(e) => handleAppleRecentAddr(item.node, e)}>
					{item.node.addr1 && <p>{item.node.addr1} {item.node.addr2}</p>}
					<span>지번 : {item.node.oldAddr1} {item.node.oldAddr2}</span>
				</div>
				<a href="#this" className="txtHide btnAdrDel" onClick={(e) => handleDeleteRecentAddr(item.node, e)}>주소삭제</a>
			</li>
		)
	})
}
export default SetAddress