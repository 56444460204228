import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import Popup from './Popup';

class TermsList extends React.Component {
	render() {
		return (
			<div id="reWrap">
				<div id="header" className="subHeader">
					<div className="header">
						<NavLink to="/mypage" className="txtHide btnHback">뒤로가기</NavLink>
						<h1>제공 서비스별 약관 </h1>
					</div>
				</div>
				<div id="container">
					<div id="contents" className="subCnts10">
						<div className="mpListMenu">
							<ul>
								<li><Popup href="https://makeshop.notion.site/_-b5cb1f76f0eb43ddbba93ac6ca43ce19">통합회원 이용약관</Popup></li>
								<li><Popup href="https://makeshop.notion.site/_-85989047544f427ba37660c10d6facc7">서비스 이용약관</Popup></li>
								<li><Popup href="https://makeshop.notion.site/_-2869f0a7cfda4bb8aad99b2e46de4e32">개인정보처리방침</Popup></li>
								<li><Popup href="https://makeshop.notion.site/_-69f04fa78ac14f6d99ea42d45aba8b3c">위치기반 서비스 이용약관</Popup></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default withRouter(TermsList)