import React from "react";
import { NavLink } from "react-router-dom";
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { loader } from 'graphql.macro';
import { gqlErrorHandler } from './Library';
import { withApolloClient } from './ApolloClient';
import { Query } from '@apollo/client/react/components';

const FAQS_QUERY = loader('../gql/faq_list.gql');
const FAQ_QUERY = loader('../gql/faq.gql');

const Faq = (props) => {
	let match = useRouteMatch();
	return (
		<Switch>
			<Route exact path={`${match.path}`} >
				<FaqList {...props} />
			</Route>
			<Route path={`${match.path}/view/:id`} component={FaqView} />
		</Switch>
	)
}

class FaqList extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			title: '',
			gongId: process.env.REACT_APP_SERVICE_GID,
			faqList: {
				'MEMBER': [],
				'ORDER': [],
				'PAY': [],
				'SERVICE': [],
				'ETC': []
			},
			noCotent: false
		}
	}

	componentDidMount() {
		this.getData();
	}

	getData = () => {
		const newFaqList = {
			'MEMBER': [],
			'ORDER': [],
			'PAY': [],
			'SERVICE': [],
			'ETC': []
		};
		this.props.client.query({
			query: FAQS_QUERY,
			variables: this.state,
			fetchPolicy: 'network-only',
		}).then((result) => {
			let rows = result.data.gongFaqs.edges;
			if (rows.length > 0) {
				rows.forEach(v => {
					newFaqList[v.node.category].push(v.node);
				});
				this.setState({
					faqList: newFaqList,
					noCotent: false
				});
			} else {
				this.setState({
					faqList: newFaqList,
					noCotent: true
				});
			}
		}).catch((err) => { gqlErrorHandler(err) });
	}

	render() {
		const displayStyle = { display: 'block' };
		const noneDisplayStyle = { display: 'none' };
		const { faqList, noCotent, title, acitveTab } = this.state;

		return (
			<div id="reWrap">
				<div id="header" className="subHeader">
					<div className="header">
						<NavLink to="/mypage" className="txtHide btnHback">뒤로가기</NavLink>
						<h1>문의하기</h1>
						<a href="tel:1899-1010" class="txtHide btnCsCall">고객센터 전화걸기</a>
					</div>
				</div>
				<div id="container">
					<div id="contents" className="subCnts9">
						<div className="comSch2 fixed">
							<div className="tabUi">
								<ul>
									<li><NavLink to="/mypage/faq"><button className="on" data-conts="faq" data-btn="sch">FAQ</button></NavLink></li>
									<li><NavLink to="/mypage/inquiry"><button data-conts="oneTo" data-btn="btns">1:1 문의</button></NavLink></li>
								</ul>
							</div>
							<div className="sch">
								<input type="search" value={title} onChange={(e) => {
									this.setState({ title: e.target.value },
										() => this.getData());
								}} placeholder="검색어를 입력해주세요" />
								<a href="#this" className="txtHide btnSch">검색</a>
							</div>
						</div>
						<div className="inquiryList">
							<div className="faq tabSectoin on" id="faq">
								{!noCotent && <ul>
									{
										faqList.MEMBER.length > 0 &&
										<li className={acitveTab === 'MEMBER' ? "on" : ""} onClick={() => { acitveTab === 'MEMBER' ? this.setState({ acitveTab: '' }) : this.setState({ acitveTab: 'MEMBER' }) }}>
											<button>회원가입/로그인</button>
											<div className="faqQ" style={acitveTab === 'MEMBER' ? displayStyle : noneDisplayStyle}>
												<ul>
													{faqList.MEMBER && faqList.MEMBER.map((v, k) => {
														return (
															<li key={k}><NavLink to={"/mypage/faq/view/" + v.pk}>{v.title}</NavLink></li>
														)
													})}
												</ul>
											</div>
										</li>
									}
									{
										faqList.ORDER.length > 0 &&
										<li className={acitveTab === 'ORDER' ? "on" : ""} onClick={() => { acitveTab === 'ORDER' ? this.setState({ acitveTab: '' }) : this.setState({ acitveTab: 'ORDER' }) }}>
											<button>주문/배달</button>
											<div className="faqQ" style={acitveTab === 'ORDER' ? displayStyle : noneDisplayStyle}>
												<ul>
													{faqList.ORDER && faqList.ORDER.map((v, k) => {
														return (
															<li key={k}><NavLink to={"/mypage/faq/view/" + v.pk}>{v.title}</NavLink></li>
														)
													})}
												</ul>
											</div>
										</li>
									}
									{
										faqList.PAY.length > 0 &&
										<li className={acitveTab === 'PAY' ? "on" : ""} onClick={() => { acitveTab === 'PAY' ? this.setState({ acitveTab: '' }) : this.setState({ acitveTab: 'PAY' }) }}>
											<button>결제/취소/환불</button>
											<div className="faqQ" style={acitveTab === 'PAY' ? displayStyle : noneDisplayStyle}>
												<ul>
													{faqList.PAY && faqList.PAY.map((v, k) => {
														return (
															<li key={k}><NavLink to={"/mypage/faq/view/" + v.pk}>{v.title}</NavLink></li>
														)
													})}
												</ul>
											</div>
										</li>
									}
									{
										faqList.SERVICE.length > 0 &&
										<li className={acitveTab === 'SERVICE' ? "on" : ""} onClick={() => { acitveTab === 'SERVICE' ? this.setState({ acitveTab: '' }) : this.setState({ acitveTab: 'SERVICE' }) }}>
											<button>서비스 이용</button>
											<div className="faqQ" style={acitveTab === 'SERVICE' ? displayStyle : noneDisplayStyle}>
												<ul>
													{faqList.SERVICE && faqList.SERVICE.map((v, k) => {
														return (
															<li key={k}><NavLink to={"/mypage/faq/view/" + v.pk}>{v.title}</NavLink></li>
														)
													})}
												</ul>
											</div>
										</li>
									}
									{
										faqList.ETC.length > 0 &&
										<li className={acitveTab === 'ETC' ? "on" : ""} onClick={() => { acitveTab === 'ETC' ? this.setState({ acitveTab: '' }) : this.setState({ acitveTab: 'ETC' }) }}>
											<button>기타</button>
											<div className="faqQ" style={acitveTab === 'ETC' ? displayStyle : noneDisplayStyle}>
												<ul>
													{faqList.ETC && faqList.ETC.map((v, k) => {
														return (
															<li key={k}><NavLink to={"/mypage/faq/view/" + v.pk}>{v.title}</NavLink></li>
														)
													})}
												</ul>
											</div>
										</li>
									}
								</ul>}
								{noCotent && <ul>
									<li className="noData">
										<hr className="hrBk" />
										<p>등록된 문의내역이 없습니다.</p>
									</li>
								</ul>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

class FaqView extends React.Component {
	render() {
		const { id } = this.props.match.params;
		return (
			<div id="reWrap">
				<div id="header" className="subHeader">
					<div className="header">
						<NavLink to="/mypage/faq" className="txtHide btnHback">뒤로가기</NavLink>
						<h1>문의하기</h1>
						<a href="tel:1899-1010" class="txtHide btnCsCall">고객센터 전화걸기</a>
					</div>
				</div>
				<div id="container">
					<div id="contents" className="subCnts10">
						<Query query={FAQ_QUERY} variables={{ pk: id }}>
							{({ loading, error, data }) => {
								if (loading) return null
								if (error) { gqlErrorHandler(error); return null }
								if (data.gongFaq === '') {
									return <></>
								}
								return (
									<div className="faqView">
										<div className="thDiv">
											<h4>[{data.gongFaq.categoryName}]</h4>
											<p>{data.gongFaq.title}</p>
										</div>
										<div className="tdDiv" style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{__html: data.gongFaq.content}}></div>
									</div>
								)
							}}
						</Query>

					</div>
				</div>
			</div>
		)
	}
}

export default withApolloClient(Faq);