import React from "react";
import { chkImageUrl } from './Library'
import usePayTypeName from "../hook/usePayTypeName"


const formatter = new Intl.NumberFormat()

export default function ShopUnit(props) {
	const handleToOrder = (id, e) => {
		e.preventDefault();
		const tokenStr = localStorage.getItem('access_token') ? `?token=${localStorage.getItem('access_token')}` : ''
		window.open(`${process.env.REACT_APP_SERVER_DOMAIN}/shop/${id}${tokenStr}`)
	}

	let payTypeName = usePayTypeName(props.shop.payType)
	// 현재위치에 따른 배송비
	let ship_price = props.shop.shipPrice

	let ship_price_min = ship_price[0]
	if (ship_price_min === -1) {
		ship_price_min = "배달불가"
	}
	let ship_price_max = ship_price[1]
	if (ship_price_max === -1) {
		ship_price_max = "배달불가"
	}
	return (
		<li>
			<a href="#this" onClick={(e) => handleToOrder(props.shop.pk, e)}>
				<div className={`img ${props.shop.isOpened ? '' : 'close'}`}><img src={chkImageUrl(props.shop.imgSize)} alt="" onError={e => e.target.style.display = 'none'} /></div>
				<div className="cnt">
					<h3>{props.shop.name}</h3>
					<span className="distance">
						{props.m < 1000
							? props.m + 'm'
							: formatter.format((props.m / 1000).toFixed(1)) + 'km'
						}
					</span>
					<div className="divState">
						{props.shop.useReservation && <span className="icoSt">매장식사</span>}
						{props.shop.useTakeout && <span className="icoTo">포장 {props.shop.deliveryCookingTime ? <>{`${props.shop.deliveryCookingTime}분`}</> : null}</span>}
						{props.shop.useDelivery && <span className="icoDe">배달 {(props.shop.deliveryTimeMin && props.shop.deliveryTimeMax) ? <>{`${props.shop.deliveryTimeMin}분 ~ ${props.shop.deliveryTimeMax > 60 ? '60+' : props.shop.deliveryTimeMax}분`}</> : null}</span>}
						{props.shop.hasCoupon && <span className="icoCo">쿠폰</span>}
					</div>
					<p>
						<span>최소주문금액 {formatter.format(props.shop.deliveryMinPrice)}원</span>
						{props.shop.useDelivery && <span>배달팁 {formatter.format(ship_price_min)} {(ship_price_max > ship_price_min) && ` ~ ${formatter.format(ship_price_max)}`}원</span>}
					</p>
					<p className="payHow">
						{payTypeName.join('·')}
					</p>
				</div>
			</a>
		</li>
	)
}