import { useReactiveVar } from '@apollo/client';
import { locAddressMainVar } from '../components/ApolloClient'

export function useHeaderAddr() {
	const locAddressMain = useAddrInfo()
	let addressTxt = null
	if (locAddressMain) {
		if (locAddressMain.sido && locAddressMain.gugun && locAddressMain.dong)
			addressTxt = `${locAddressMain.sido} ${locAddressMain.gugun} ${locAddressMain.dong}`
		else
			addressTxt = locAddressMain.oldAddr1
	} else {
		addressTxt = '전주시 완산구 서노송동 노송광장로 10'
	}

	return addressTxt
}

function useAddrInfo() {
	return useReactiveVar(locAddressMainVar)
}

export default useAddrInfo