import React from "react";
import { withRouter } from "react-router-dom";
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro'
import Snackbar from '@material-ui/core/Snackbar'
import { gqlErrorHandler } from './Library'
import Popup from './Popup'

const companyAgreeGql = loader("../gql/set_company_agree.gql");

const AgreeButton = (props) => {
    const [agree] = useMutation(companyAgreeGql, {
        variables:{ company: "JICA" },
        onCompleted(res) {
            props.setMessage(res.memberCompanyAgree.msg)
            props.setOpenSnackbar(true)
            
            if (res.memberCompanyAgree.result) {
                // 새창(or 팝업) 페이지 이동
                setInterval(() => {
					// www.전주구독.kr 영어 도메인 https://xn--2e0bk4jh4sm2a.kr

					// 아이폰에서 한글 문제가 있어서 URL디코딩을 했으나. 아이폰에서 URL디코딩된 문자열을 실행하기 전에 인코딩해서 AppScheme가 미동작함
					// 해서 영어 도메인이 있다고 전달 받아서 영어 도메인으로 이동시킴

					/* global AppScheme */
					typeof AppScheme !== "undefined" && AppScheme(`sp00order${process.env.REACT_APP_SERVICE_GID}://outbrowser?url=https://xn--2e0bk4jh4sm2a.kr`)
					if (typeof AppScheme == "undefined") {
						window.location.href = "https://xn--2e0bk4jh4sm2a.kr";
					}
                }, 1000)
            }
        },
        onError(err) { gqlErrorHandler(err) }
    })

    const handleAgree = () => {
        if (props.state.chk_1 && props.state.chk_2) { 
            agree()
		} else {
			props.setMessage("필수 약관에 동의해 주세요")
            props.setOpenSnackbar(true)
		}
       
    }
    return (<div className="btnCenter">
        <a href="#this" className="btnTy2" style={{ width:'74px', display:'inline-block'}} onClick={(e) => { e.preventDefault(); window.close(); }}>미동의</a>
        <a href="#this" className="btnTy3" style={{ width:'74px', display:'inline-block', marginLeft:'10px' }} onClick={(e) => { e.preventDefault(); handleAgree(); }}>동의</a>
    </div>
    )
}
export default withRouter(class AgreeTerms extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openSnackbar: false,
            message: '',

            chk_all: false,
			chk_1: false,
			chk_2: false
        };

        this.setMessage = this.setMessage.bind(this);
        this.setOpenSnackbar = this.setOpenSnackbar.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
    }

	setMessage(message) {
        this.setState({ message: message })
    }
    setOpenSnackbar(boolean) {
        this.setState({ openSnackbar: boolean })
    }

    handleCheck(e) {
		const value = e.target.checked;
		const name = e.target.name;
		let preState = this.state;

		if (name === 'chk_all') {
			Object.keys(preState).map((key) => preState[key] = value && key.includes("chk") );
		} else {
			preState[name] = value;

			preState.chk_all = true;
			Object.keys(preState).map((key) => preState.chk_all = preState.chk_all && preState[key]);
		}
	
		this.setState(preState);
	}
	

    render() {
        return(
            <div id="reWrap">
                
				<Snackbar
					open={this.state.openSnackbar}
					onClose={() => this.setState({ openSnackbar: false, message: '' })}
					anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
					message={this.state.message}
					autoHideDuration={3000}
				/>
                
				<div id="header" className="subHeader">
					<div className="header">
						<h1>이용약관</h1>
					</div>
				</div>
				<div id="container">
					<div id="contents" className="subCnts">
						<div className="agreeJoin">
							<div className="allAgree">
								<label className="chkBox1">
									<input type="checkbox" name="chk_all" checked={this.state.chk_all} onChange={this.handleCheck} style={{display: 'none !important'}} />
									<span className="icon"></span>
									<span className="text">아래 약관에 모두 동의합니다.</span>
								</label>
							</div>
							<ul>
								<li>
									<label className="chkBox1">
										<input type="checkbox" name="chk_1" checked={this.state.chk_1} onChange={this.handleCheck} style={{display: 'none !important'}} />
										<span className="icon"></span>
										<span className="arrSpan">
											<span className="text">(필수) 제휴서비스 이용약관</span>
											<Popup href="https://makeshop.notion.site/efff97774e4e480db2e7a28b785e7770">
												<img src="/img/reImg/sub/bg_ico_arr1.png" className="arrImg" onError={e => e.target.style.display = 'none'} alt="통합회원 이용약관" />
											</Popup>
										</span>
									</label>
								</li>
								<li>
									<label className="chkBox1">
										<input type="checkbox" name="chk_2" checked={this.state.chk_2} onChange={this.handleCheck} style={{display: 'none !important'}} />
										<span className="icon"></span>
										<span className="arrSpan">
											<span className="text">(필수) 전주구독 소비자 이용약관</span>
											<Popup href="https://makeshop.notion.site/2928c21033374132be8816ed452bc98d">
												<img src="/img/reImg/sub/bg_ico_arr1.png" className="arrImg" onError={e => e.target.style.display = 'none'} alt="서비스 이용약관" />
											</Popup>
										</span>
									</label>
								</li>
							</ul>
                            <AgreeButton setOpenSnackbar={this.setOpenSnackbar} setMessage={this.setMessage} state={this.state}/>
						</div>
					</div>
				</div>
                
            </div>				
        )
    }
});