import ReactDOM from "react-dom";
import React from "react";
import "../css/custom_modal.css";
import Swiper from 'swiper';
import 'swiper/css/swiper.css';
import { chkImageUrl } from './Library';

const style_popup_wrapper = { opacity: 1, visibility: 'visible', position: 'fixed', overflow: 'auto', zIndex: 1001, transition: 'all 0.3s ease 0s', width: '100%', height: '100%', top: 0, left: 0, textAlign: 'center', pointerEvents: 'none' };
const style_popup_content = { top: '50vh', transform: 'translateY(-50%)', opacity: 1, visibility: 'visible', pointerEvents: 'auto', display: 'inline-block', outline: 'none', transition: 'all 0.3s ease 0s', textAlign: 'left', position: 'relative', verticalAlign: 'middle' };
const style_popup_background = { opacity: 0.6, visibility: 'visible', backgroundColor: 'black', position: 'fixed', inset: 0, zIndex: 1000, transition: 'all 0.3s ease 0s' };

const CustomModal = () => { };
CustomModal.confirm = (props) => {
    const container = document.createElement("div");

    let title = props.title ? props.title : '';
    let content = props.content ? props.content : '';
    let okText = props.okText ? props.okText : '확인';
    let cancelText = props.cancelText ? props.cancelText : '취소';
    let dimmed = props.dimmed === false ? false : true;

    let handleOk = function handleOk(e) {
        e.preventDefault()
        var onOk = props.onOk;
        if (onOk) {
            onOk(e);
        }
        container.remove();
    };
    let handleCancel = function handleOk(e) {
        e.preventDefault()
        var onCancel = props.onCancel;
        if (onCancel) {
            onCancel(e);
        }
        container.remove();
	};
	let handlerClose = function handleClose(e) {
		container.remove();
	}
	
	let innerTitle;
	let innerContent;
	if("string" === typeof title) {
		innerTitle = <span dangerouslySetInnerHTML={{__html: title.replace('\n', '<br/>')}}></span>
	} else {
		innerTitle = title;
	}
	if("string" === typeof content) {
		innerContent = <span className="txtSub1" dangerouslySetInnerHTML={{__html: content.replace('\n', '<br/>')}}></span>;
	} else {
		innerContent = <span className="txtSub1">{content}</span>
	}

    container.setAttribute("custom-confirm-view", "");
    document.body.appendChild(container);
    ReactDOM.render(
        <>
            <div className="popup_wrapper popup_wrapper_visible" style={style_popup_wrapper}>
                <div className="layerAlertCom popup_content" style={style_popup_content}>
                    <div className="layerBox">
                        <div className="layerCnt">
                            <div className="layerAlert">
								<p>
									{innerTitle}
									{innerContent}
								</p>
                                <div className="btnRight">
                                    <a href="#this" onClick={handleCancel} className="btnAlert2">{cancelText}</a>
                                    <a href="#this" onClick={handleOk} className="btnAlert1">{okText}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {dimmed === true && <div className="popup_background" onClick={handlerClose} style={style_popup_background}></div>}
        </>,
        container
    );
};

CustomModal.alert = (props) => {
    const container = document.createElement("div");
    container.setAttribute("custom-confirm-view", "");

    let content = props.content ? props.content : '';
    let okText = props.okText ? props.okText : '확인';
    let dimmed = props.dimmed === false ? false : true;

    let handleOk = function handleOk(e) {
        e.preventDefault()
        var onOk = props.onOk;
        if (onOk) {
            onOk(e);
        }
        container.remove();
	};
	let handlerClose = function handleClose(e) {
		container.remove();
	}
	
	let innerContent;
	if("string" === typeof content) {
		innerContent = <span dangerouslySetInnerHTML={{__html: content.replace('\n', '<br/>')}}></span>;
	} else {
		innerContent = <span>{content}</span>
	}

    document.body.appendChild(container);
    ReactDOM.render(
        <>
            <div className="popup_wrapper popup_wrapper_visible" style={style_popup_wrapper}>
                <div className="layerAlertCom popup_content" style={style_popup_content}>
                    <div className="layerBox">
                        <div className="layerCnt">
                            <div className="layerAlert">
								<p>{innerContent}</p>
                                <div className="btnRight">
                                    <a href="#this" onClick={handleOk} className="btnAlert1">{okText}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {dimmed === true && <div className="popup_background" onClick={handlerClose} style={style_popup_background}></div>}
        </>,
        container
    );
};


CustomModal.warning = (props) => {
    const container = document.createElement("div");

    let title = props.title ? props.title : '';
    let content = props.content ? props.content : '';
    let okText = props.okText ? props.okText : '확인';
    let dimmed = props.dimmed === false ? false : true;

    let handleOk = function handleOk(e) {
        e.preventDefault()
        var onOk = props.onOk;
        if (onOk) {
            onOk(e);
        }
        container.remove();
	};
	let handlerClose = function handleClose(e) {
		container.remove();
	}
	
	let innerTitle;
	let innerContent;
	if("string" === typeof title) {
		innerTitle = <span dangerouslySetInnerHTML={{__html: title.replace('\n', '<br/>')}}></span>
	} else {
		innerTitle = title;
	}
	if("string" === typeof content) {
		innerContent = <span className="txtSub1" dangerouslySetInnerHTML={{__html: content.replace('\n', '<br/>')}}></span>;
	} else {
		innerContent = <span className="txtSub1">{content}</span>
	}

    container.setAttribute("custom-confirm-view", "");
    document.body.appendChild(container);
    ReactDOM.render(
        <>
            <div className="popup_wrapper popup_wrapper_visible" style={style_popup_wrapper}>
                <div className="layerAlertCom popup_content" style={style_popup_content}>
                    <div className="layerBox">
                        <div className="layerCnt">
                            <div className="layerAlert">
								<p>
									{innerTitle}
									{innerContent}
								</p>
                                <div className="btnRight">
                                    <a href="#this" onClick={handleOk} className="btnAlert1">{okText}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {dimmed === true && <div className="popup_background" onClick={handlerClose} style={style_popup_background}></div>}
        </>,
        container
    );
};

CustomModal.success = (props) => {
    CustomModal.alert(props);
}
CustomModal.error = (props) => {
    CustomModal.alert(props);
}
CustomModal.info = (props) => {
    CustomModal.confirm(props);
}

CustomModal.zoomImage = (props) => {
    const container = document.createElement("div");

	let images = props.images ? props.images : '';
	
    let handleClose = function handleClose(e) {       
        container.remove();
	};
	
	let swiper = function swipter(e) {
		new Swiper('.swiper-container', {
			pagination: {
				el: ".swiper-pagination",
				type: "fraction",
			}
		});
	};		

    container.setAttribute("custom-confirm-view", "");
    document.body.appendChild(container);
    ReactDOM.render(
		<div className="popup_wrapper popup_wrapper_visible" style={style_popup_wrapper}>
			<div id="layerReviewPhoto" className="layerMenuPhoto popup_content" style={style_popup_content}>
				<div className="bgModal100" onClick={handleClose}></div>
				<div className="menuPhotoBox" style={{pointerEvents: 'none'}}>
					<div className="photo">
						<div className="swiper-container">
							<ul className="swiper-wrapper">
								{images.map((img, k) => (
									<li className="swiper-slide" key={k}><img src={chkImageUrl(img)} alt="" onError={e => e.target.style.display = 'none'} /></li>									
								))}								
							</ul>
						</div>
					</div>
					<div className="swiper-pagination"></div>
				</div>
				<a href="#this" onClick={handleClose} className="btnLyClose1 txtHide layerReviewPhoto_close">닫기</a>
			</div>
		</div>,
		container, swiper		
    );
}


export default CustomModal;