import Modal from './CustomModal'
import { Modal as antd_Modal } from 'antd'
import { locAddressMainVar } from './ApolloClient'
import $ from 'jquery'

const gqlErrorHandler = (err, tip = null) => {
	console.log(err);
	antd_Modal.confirm({
		title: "오류가 발생했습니다. " + (tip !== null ? `(${tip})` : ''),
		okText: "새로고침",
		cancelText: "취소",
		onOk() {
			window.location.reload();
		},
		onCancel() { },
	});
}

const successHandler = (contentText) => {
	Modal.success({
		content: contentText
	});
}

const warningHandler = (titleText, contentText) => {
	Modal.success({
		title: titleText,
		content: contentText
	});
}

const isLogin = () => {
	const time = Math.round(new Date().getTime() / 1000)
	if (time >= localStorage.getItem("token_expires")) return false;

	if (localStorage.access_token && localStorage.member_email) {
		return true;
	} else {
		return false;
	}
}

const getLocation = (callback, justReturnDefault) => {
	const defaultLL = { coords: { latitude: 37.4975114, longitude: 127.0267694 } } // 에러, 위치접근권한미부여 등 일때
	if (justReturnDefault) return defaultLL
	const opt = {
		enableHighAccuracy: false,
		timeout: 5000,
		maxiumAge: 60000,
	}
	try {
		navigator.geolocation.getCurrentPosition(
			(pos) => callback(pos, true),
			(err) => {
				console.error(err);
				callback(defaultLL, false)
			},
			opt
		);
	} catch (err) {
		console.error(err);
		callback(defaultLL, false)
	}
}
let kakaoMap
const createKakaoMap = (opt, callback) => {
	const appKey = '7caa9e9a4de93f51d6ed485e9874e22b'  //'4b9d5a750134597d554676e106c6cc07' //7caa9e9a4de93f51d6ed485e9874e22b
	const script = document.createElement("script")
	script.async = true
	script.src = `https://dapi.kakao.com/v2/maps/sdk.js?appkey=${appKey}&libraries=services&autoload=false`
	script.id = 'kakao-map-script'
	document.head.appendChild(script)

	script.onload = () => {
		window.kakao.maps.load(() => {
			let container = document.getElementById(opt.id)
			let options = {
				center: new window.kakao.maps.LatLng(opt.lat, opt.lng),
				level: opt.level,
			};

			kakaoMap = new window.kakao.maps.Map(container, options)
			callback && callback(kakaoMap)
		});
	}
}

const createDaumPostcode = (opt, ocCallback, osCallback) => {
	const script = document.createElement("script")
	script.async = true
	script.src = `https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js`
	script.id = 'daum-postcode-script'
	document.head.appendChild(script)

	script.onload = () => {
		const element_wrap = document.getElementById(opt.id)
		new window.daum.Postcode({
			oncomplete: function (data) {
				ocCallback(data)
			},
			// 우편번호 찾기 화면 크기가 조정되었을때 실행할 코드를 작성하는 부분. iframe을 넣은 element의 높이값을 조정한다.
			onresize: function (size) {
				element_wrap.style.height = size.height + 'px';
			},
			width: '100%',
			height: '100%',
			hideMapBtn: true,
			onsearch: function (data) {
				osCallback(data)
			},
			theme: { bgColor: '#FFFFFF' }
		}).embed(element_wrap);
	}
}

const saveLoginMember = (id, nick, email, mobile) => {
	// 로그인관련 값 localstorage 저장
	localStorage.member_id = id;
	localStorage.member_nick = nick;
	localStorage.member_email = email;
	localStorage.member_mobile = mobile;

	if (id) {
		/* global AppScheme */
		typeof AppScheme !== "undefined" && AppScheme(`sp00order${process.env.REACT_APP_SERVICE_GID}://member?id=${id}`);
	}

	let pathname = '/';
	if (localStorage.prevpath) {
		pathname = localStorage.prevpath;
		localStorage.prevpath = '';
	}
	window.location.replace(pathname);
}
const chkImageUrl = (url) => {
	if (!url) {
		return '';
	}
	let url_ = String(url)
	if (/:\/\//.test(url_) === false) {
		url_ = process.env.REACT_APP_IMAGES_DOMAIN + url_ // 도메인 없는 데이터.
	}
	return url_
}
export const setLocAddressMain = (addr) => {
	/* 사용될 필드 참고용으로 기본구조 정의하고 새 값과 머지 */
	let locAddr = {
		oldAddr1: null,
		oldAddr2: null,
		addr1: null,
		addr2: null,
		sido: null,
		gugun: null,
		dong: null,
		lng: null,
		lat: null,
		hCode: null,
		bCode: null,
	}
	locAddr = addr ? { ...locAddr, ...addr } : null;

	/* 로컬스토리지에 저장 & 로컬스테이트 갱신 */
	localStorage.setItem("locAddressMain", JSON.stringify(locAddr))
	locAddressMainVar(locAddr)
}
export { gqlErrorHandler, successHandler, warningHandler, getLocation, createKakaoMap, kakaoMap, createDaumPostcode, saveLoginMember, chkImageUrl, isLogin }

export const headerShadow = function () {
	$('#reWrap').scroll(function () {
		hdScrollWrap();
	});
	$(window).scroll(function () {
		hdScrollWin();
	});
	function hdScrollWrap() {
		if ($('#reWrap').scrollTop() === 0) {
			$('#header').removeClass('scroll');
		} else {
			$('#header').addClass('scroll');
		}
	}
	function hdScrollWin() {
		if ($(window).scrollTop() === 0) {
			$('#header').removeClass('scroll');
		} else {
			$('#header').addClass('scroll');
		}
	}
	hdScrollWrap();
	hdScrollWin();
}
export const bodyScrollLock = {
	posY: 0,
	lock() {
		const body = document.querySelector('body');
		this.posY = window.pageYOffset;
		body.style.overflow = 'hidden';
		body.style.position = 'fixed';
		body.style.top = `-${this.posY}px`;
		body.style.width = '100%';
		body.classList.add('nowBodyScrollLock')
	},
	unlock() {
		const body = document.querySelector('body');
		body.style.removeProperty('overflow');
		body.style.removeProperty('position');
		body.style.removeProperty('top');
		body.style.removeProperty('width');
		window.scrollTo(0, this.posY);
		body.classList.remove('nowBodyScrollLock')
	}
}