import React from "react";
import axios from "axios";
import { withRouter, NavLink } from "react-router-dom";
import Modal from './CustomModal';
import { withApolloClient } from './ApolloClient';
import InputDelButton from "./InputDelButton";
import BirthForm from "./BirthForm";
import $ from "jquery";
import Timer from './Timer';

const modes = ['password', 'mobile', 'nickname', 'sex', 'birth'];
const style_popup_wrapper = { opacity: 1, visibility: 'visible', position: 'fixed', overflow: 'auto', zIndex: 101, transition: 'all 0.3s ease 0s', width: '100%', height: '100%', top: 0, left: 0, textAlign: 'center', pointerEvents: 'none' };
const style_popup_content = { top: '50vh', transform: 'translateY(-50%)', opacity: 1, visibility: 'visible', pointerEvents: 'auto', display: 'inline-block', outline: 'none', transition: 'all 0.3s ease 0s', textAlign: 'left', position: 'relative', verticalAlign: 'middle' };
const style_popup_background = { opacity: 0.6, visibility: 'visible', backgroundColor: 'black', position: 'fixed', inset: 0, zIndex: 100, transition: 'all 0.3s ease 0s' };

class SetInfo extends React.Component {
	constructor(props) {
		super(props);

		let nowMod = Array(modes.length);
		modes.map((val, idx) => nowMod[idx] = false);
		this.state = {
			nowMod: nowMod,
			values: {
				passwordNow: '',
				password: '',
				passwordCheck: '',
				mobile: '',
				sendedMobile: '',
				confirmNo: '',
				nickname: '',
				sex: '',
				birth: ''
			},
			memberInfo: {
				email: '',
				mobile: '',
				nickname: '',
				sex: '',
				birth: '',
				pw_changed_at: ''
			},
			msgTimeout: false,
			msgSendDate: null,
			validate: {
				password: false,
				passwordCheck: false
			},
			validateContent: {
				password: '',
				passwordCheck: ''
			}
		};

		axios.get(`${process.env.REACT_APP_SERVER_REST}/members/info`,
			{ headers: { Authorization: 'Bearer ' + localStorage.access_token } })
			.then(res => {
				if (res.data.result) {
					const memberInfo = {
						nickname: res.data.nick,
						email: res.data.email,
						mobile: res.data.phone,
						sex: res.data.sex,
						birth: res.data.birth,
						pw_changed_at: res.data.pw_changed_at
					};

					let defaultValues = this.state.values;
					defaultValues.sex = parseInt(res.data.sex) === 2 ? 2 : 1;
					this.setState({
						memberInfo: memberInfo,
						values: defaultValues
					});
				}
			});

		this.handleModify = this.handleModify.bind(this);
		this.handleClear = this.handleClear.bind(this);
		this.handleLogout = this.handleLogout.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.handleSubmitNickname = this.handleSubmitNickname.bind(this);
		this.handleChageSex = this.handleChageSex.bind(this);
		this.handleSubmitSex = this.handleSubmitSex.bind(this);
		this.handleSend = this.handleSend.bind(this);
		this.handleSubmitMobile = this.handleSubmitMobile.bind(this);
		this.handleSubmitPassword = this.handleSubmitPassword.bind(this);
		this.handleSubmitBirth = this.handleSubmitBirth.bind(this);
		this.handleDestroy = this.handleDestroy.bind(this);
		this.handleTimeout = this.handleTimeout.bind(this);
	}
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	handleTimeout() {
		this.setState({ msgTimeout: true });
		return false;
	}

	handleModify(id, e) {
		e.preventDefault();
		let nowMod = this.state.nowMod;
		modes.map((val, idx) => nowMod[idx] = val === id);
		this.setState({ nowMod: nowMod });
	}

	handleClear(e) {
		if (e) e.preventDefault();
		let nowMod = this.state.nowMod;
		modes.map((val, idx) => nowMod[idx] = false);
		this.setState({ nowMod: nowMod });
	}

	handleInput(mode, value) {
		let values = this.state.values;
		Object.keys(values).map((key) => key === mode ? values[key] = value : null);

		let validate = this.state.validate;
		let validateContent = this.state.validateContent;

		// 비밀번호 입력값 검증
		switch (mode) {
			case 'passwordNow':
				break;
			case 'password':
				const regPassword = /^(?!((?:[A-Za-z]+)|(?:[0-9]+)|(?:[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]+))$)[A-Za-z\d!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]{8,20}$/;

				if (value) {
					if (regPassword.test(value)) {
						validate.password = true;
						validateContent.password = '사용 가능한 비밀번호입니다.';
					} else {
						validate.password = false;
						validateContent.password = '8~20자의 영문, 숫자, 특수문자 2종류 이상 입력해 주세요.';
					}
					//비밀번호 체크
					if (value === values.passwordCheck) {
						validate.passwordCheck = true;
						validateContent.passwordCheck = '비밀번호가 일치합니다.';
					} else {
						validate.passwordCheck = false;
						validateContent.passwordCheck = '비밀번호가 일치하지 않습니다.';
					}
				} else {
					validate.password = false;
					validateContent.password = null;

					if (values.passwordCheck) {
						validate.passwordCheck = false;
						validateContent.passwordCheck = '비밀번호가 일치하지 않습니다.';
					}
				}
				break;
			case 'passwordCheck':
				if (value) {
					if (value === values.password) {
						validate.passwordCheck = true;
						validateContent.passwordCheck = '비밀번호가 일치합니다.';
					} else {
						validate.passwordCheck = false;
						validateContent.passwordCheck = '비밀번호가 일치하지 않습니다.';
					}
				} else {
					validate.passwordCheck = false;
					validateContent.passwordCheck = null;
				}
				break;
			case 'confirmNo':
				break;
			case 'nickname':
				break;
			case 'mobile':
				break;
			default:
				return;
		}

		this.setState({ values: values, validate: validate, validateContent: validateContent });
	}

	handleChageSex(e) {
		if (e.target.value) {
			let values = this.state.values;
			values.sex = parseInt(e.target.value);
			this.setState({ values: values });
		}
	}

	handleSubmitSex(e) {
		e.preventDefault();
		if (this.state.values.sex) {
			axios.post(`${process.env.REACT_APP_SERVER_REST}/members/info/edit`,
				{ sex: this.state.values.sex },
				{ headers: { Authorization: 'Bearer ' + localStorage.access_token } })
				.then(res => {
					if (res.data.result) {
						let { values, memberInfo } = this.state;
						memberInfo.sex = values.sex;
						values.sex = '';
						this.setState({ memberInfo: memberInfo });

						Modal.success({
							content: '성별설정이 완료되었습니다.',
							onOk: this.handleClear()
						});

					} else {
						Modal.warning({
							content: res.data.msg
						});
					}
				});
		}
	}

	handleSubmitNickname(e) {
		e.preventDefault();
		const nickname = this.state.values.nickname.trim();
		if (nickname.length > 1 && nickname.length < 11) {
			axios.post(`${process.env.REACT_APP_SERVER_REST}/members/info/edit`,
				{ nick: nickname },
				{ headers: { Authorization: 'Bearer ' + localStorage.access_token } })
				.then(res => {
					if (res.data.result) {
						let { values, memberInfo } = this.state;
						memberInfo.nickname = values.nickname;
						localStorage.member_nick = values.nickname;
						values.nickname = '';
						this.setState({ memberInfo: memberInfo });

						Modal.success({
							content: '닉네임설정이 완료되었습니다.',
							onOk: this.handleClear()
						});

					} else {
						Modal.warning({
							content: res.data.msg
						});
					}
				});
		} else {
			Modal.warning({ content: '닉네임은 1자이상 10자 이내로 입력하세요.' });
			//$('input[name=nickname]').focus();
		}
	}

	handleSend(e) {
		e.preventDefault();
		let values = this.state.values;
		if (!values.mobile) return;
		axios.post(`${process.env.REACT_APP_SERVER_REST}/members/phone/edit`,
			{ phone: values.mobile },
			{ headers: { Authorization: 'Bearer ' + localStorage.access_token } })
			.then(res => {
				if (res.data.result) {
					Modal.success({ content: '인증번호를 전송했습니다.' });
					// 인증번호가 전송되었을 경우 전송된 휴대폰번호를 저장
					values.sendedMobile = values.mobile;
					this.setState({ values: values, msgTimeout: false, msgSendDate: new Date().getTime() });
				} else {
					Modal.warning({ content: res.data.msg });
				}
			})
			.catch(res => { Modal.error({ content: '인증번호 전송중 오류가 발생하였습니다.' }) });
	}

	handleSubmitMobile(e) {
		e.preventDefault();
		let values = this.state.values;
		if (values.sendedMobile && values.confirmNo) {
			axios.post(`${process.env.REACT_APP_SERVER_REST}/members/phone/ok`,
				{ auth: values.confirmNo },
				{ headers: { Authorization: 'Bearer ' + localStorage.access_token } })
				.then(res => {
					if (res.data.result) {
						let memberInfo = this.state.memberInfo;
						memberInfo.mobile = values.sendedMobile;
						localStorage.member_mobile = values.sendedMobile;
						values.mobile = '';
						values.sendedMobile = '';
						values.confirmNo = '';
						this.setState({ memberInfo: memberInfo });
						Modal.success({
							content: '휴대폰번호 변경이 완료되었습니다.',
							onOk: this.handleClear()
						});
					} else {
						Modal.warning({ content: res.data.msg });
					}
				})
				.catch(res => { Modal.error({ content: '인증번호 전송중 오류가 발생하였습니다.' }) });
		}
	}

	handleSubmitPassword(e) {
		e.preventDefault();
		let values = this.state.values;
		if (values.passwordNow && values.password && values.passwordCheck) {
			const regPassword = /^(?!((?:[A-Za-z]+)|(?:[0-9]+)|(?:[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]+))$)[A-Za-z\d!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]{8,20}$/;
			if (!regPassword.test(values.password)) {
				Modal.warning({ content: '비밀번호는 영문+숫자 8자리이상 입력해 주세요.' });
				$('input[name=password]').focus();
				return;
			}

			if (values.password !== values.passwordCheck) {
				Modal.warning({ content: '비밀번호가 일치하지 않습니다. 다시 입력해 주세요.' });
				$('input[name=passwordCheck]').focus();
				return;
			}

			axios.post(`${process.env.REACT_APP_SERVER_REST}/v2/api/member/password/change`,
				`new_pw=${encodeURIComponent(values.password)}&old_pw=${encodeURIComponent(values.passwordNow)}`,
				{ headers: { Authorization: 'Bearer ' + localStorage.access_token } })
				.then(res => {
					if (res.data.result) {
						values.password = '';
						values.passwordNow = '';
						values.passwordCheck = '';
						this.setState({ values: values });

						axios.get(`${process.env.REACT_APP_SERVER_REST}/members/info`,
							{ headers: { Authorization: 'Bearer ' + localStorage.access_token } })
							.then(res => {
								if (res.data.result) {
									const memberInfo = {
										nickname: res.data.nick,
										email: res.data.email,
										mobile: res.data.phone,
										sex: res.data.sex,
										birth: res.data.birth,
										pw_changed_at: res.data.pw_changed_at
									};
									this.setState({ memberInfo: memberInfo });
								}
							});

						Modal.success({
							content: '비밀번호 변경이 완료되었습니다.',
							onOk: this.handleClear()
						});
					} else {
						Modal.warning({ content: res.data.msg });
					}
				})
				.catch(res => { Modal.error({ content: '비밀번호 전송중 오류가 발생하였습니다.' }) });
		}
	}

	handleSubmitBirth(birthday) {
		axios.post(`${process.env.REACT_APP_SERVER_REST}/members/info/edit`,
			{ birth: birthday },
			{ headers: { Authorization: 'Bearer ' + localStorage.access_token } })
			.then(res => {
				if (res.data.result) {
					let memberInfo = this.state.memberInfo;
					memberInfo.birth = birthday;
					this.setState({ memberInfo: memberInfo });

					Modal.success({
						content: '생년월일설정이 완료되었습니다.',
						onOk: this.handleClear()
					});

				} else {
					Modal.warning({
						content: res.data.msg
					});
				}
			});
		this.handleClear();

	}

	handleLogout(e) {
		e.preventDefault();

		Modal.confirm({
			content: '로그아웃 하시겠습니까?',
			onOk: () => {
				let appVersion = '';
				let osVersion = '';
				let locAddressMain = '';

				if (localStorage.app_version) appVersion = localStorage.app_version;
				if (localStorage.os_version) osVersion = localStorage.os_version;
				if (localStorage.locAddressMain) locAddressMain = localStorage.locAddressMain;

				localStorage.clear();
				if (appVersion) localStorage.app_version = appVersion;
				if (osVersion) localStorage.os_version = osVersion;
				if (locAddressMain) localStorage.locAddressMain = locAddressMain;
				//this.props.client.resetStore();

				/* global AppScheme */
				typeof AppScheme !== "undefined" && AppScheme(`sp00order${process.env.REACT_APP_SERVICE_GID}://logout`);

				this.props.history.push('/');
			}
		});
	}

	handleDestroy(e) {
		e.preventDefault();

		Modal.confirm({
			content: '회원탈퇴 하시겠습니까?',
			onOk: () => {
				axios.post(`${process.env.REACT_APP_SERVER_REST}/members/destroy`,
					{},
					{ headers: { Authorization: 'Bearer ' + localStorage.access_token } })
					.then(res => {
						if (res.data.result) {
							Modal.success({
								content: '회원탈퇴가 완료되었습니다.',
								onOk: () => {
									const locAddressMain = localStorage.locAddressMain ? localStorage.locAddressMain : null
									localStorage.clear();
									if (locAddressMain) localStorage.locAddressMain = locAddressMain

									this.props.client.resetStore();
									this.props.history.push('/');
								}
							});

						} else {
							Modal.warning({
								content: res.data.msg
							});
						}
					});

			}
		});
	}

	render() {
		const sex = this.state.memberInfo.sex === 1 ? '남자' : (this.state.memberInfo.sex === 2 ? '여자' : '');
		const mobile = this.state.memberInfo.mobile
			? this.state.memberInfo.mobile.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, "$1-$2-$3")
			: '';
		const birthday = this.state.memberInfo.birth
			? this.state.memberInfo.birth.substring(0, 4) + '년 '
			+ this.state.memberInfo.birth.substring(4, 6) + '월 '
			+ this.state.memberInfo.birth.substring(6, 8) + '일'
			: '';
		return (
			<>
				<div id="reWrap">
					<div id="header" className="subHeader">
						<div className="header">
							<NavLink to="/mypage" className="txtHide btnHback">뒤로가기</NavLink>
							<h1>정보수정</h1>
						</div>
					</div>
					<div id="container">
						<div id="contents" className="subCnts10">
							<div className="memInfoModify">
								<h3>기존 정보</h3>
								<div className="formWrite">
									<div className="box">
										<h4><em>*</em> 이메일</h4>
										<div className="inputSt1 disabled">
											<input type="text" name="" value={this.state.memberInfo.email} className="clearInput" placeholder="아이디(이메일) 입력" disabled="disabled" />
											<div className="inputFocus"></div>
										</div>
									</div>
									<div className="box">
										<h4><em>*</em> 비밀번호</h4>
										<div className="txtInput">
											<p>최종 변경일 : <span>{this.state.memberInfo.pw_changed_at}</span></p>
										</div>
										<a href="#this" className="btnTy5 layerPwChange_open" layer="1" onClick={(e) => this.handleModify('password', e)}>변경</a>
									</div>
									<div className="box">
										<h4><em>*</em> 휴대폰번호</h4>
										<div className="txtInput">
											<p>{mobile}</p>
										</div>
										<a href="#this" className="btnTy5 layerPhoneChange_open" layer="2" onClick={(e) => this.handleModify('mobile', e)}>변경</a>
									</div>
									<div className="box">
										<h4>닉네임</h4>
										<div className="txtInput">
											<p>{this.state.memberInfo.nickname}</p>
										</div>
										<a href="#this" className="btnTy5 layerNameChange_open" layer="3" onClick={(e) => this.handleModify('nickname', e)}>변경</a>
									</div>
									<div className="box">
										<h4>성별</h4>
										<div className="txtInput">
											<p>{sex}</p>
										</div>
										<a href="#this" className="btnTy5 layerSexChange_open" layer="4" onClick={(e) => this.handleModify('sex', e)}>변경</a>
									</div>
									<div className="box">
										<h4>생년월일</h4>
										<div className="txtInput">
											<p>{birthday}</p>
										</div>
										<a href="#this" className="btnTy5 layerBirthChange_open" layer="5" onClick={(e) => this.handleModify('birth', e)}>변경</a>
									</div>
									<div className="logoutEnd">
										<span><a href="#this" onClick={this.handleLogout}>로그아웃</a></span>
										<span><a href="#this" onClick={this.handleDestroy}>회원탈퇴</a></span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<PasswordForm nowMod={this.state.nowMod} values={this.state.values} validate={this.state.validate} validateContent={this.state.validateContent} onSubmit={this.handleSubmitPassword} onInput={this.handleInput} onClear={this.handleClear} />
				<MobileForm nowMod={this.state.nowMod} values={this.state.values} onSubmit={this.handleSubmitMobile} onInput={this.handleInput} onClear={this.handleClear} onSend={this.handleSend} onTimeout={this.handleTimeout} msgSendDate={this.state.msgSendDate} msgTimeout={this.state.msgTimeout} />
				<NicknameForm nowMod={this.state.nowMod} value={this.state.values} onSubmit={this.handleSubmitNickname} onInput={this.handleInput} onClear={this.handleClear} />
				<SexForm nowMod={this.state.nowMod} value={this.state.values} memberInfo={this.state.memberInfo} onChg={this.handleChageSex} onSubmit={this.handleSubmitSex} onClear={this.handleClear} />
				{this.state.nowMod[4] &&
					<BirthForm nowMod={this.state.nowMod} value={this.state.values} onClear={this.handleClear} onSubmit={this.handleSubmitBirth} />
				}
			</>

		);
	}
}

function PasswordForm(props) {
	const onClass = props.values.passwordNow && props.values.password && props.values.passwordCheck ? ' on' : '';
	return props.nowMod[0] ? (
		<>
			<div id="layerPwChange_wrapper" className="popup_wrapper popup_wrapper_visible" style={style_popup_wrapper}>
				<div id="layerPwChange" className="popup_content" style={style_popup_content}>
					<div className="layerBox">
						<div className="layerHd">
							<h3>비밀번호 변경</h3>
						</div>
						<div className="layerCnt">
							<div className="layerCom">
								<h4>현재 비밀번호</h4>
								<div className="box">
									<div className="inputSt1">
										<InputDelButton type="password" value={props.values.passwordNow} name="passwordNow" className="clearInput" placeholder="현재 비밀번호 입력" onInput={props.onInput} />
										<div className="inputFocus"></div>
									</div>
								</div>
								<h4>신규 비밀번호</h4>
								<div className="box">
									<div className="inputSt1">
										<InputDelButton type="password" value={props.values.password} name="password" className="clearInput" placeholder="신규 비밀번호 입력(영문, 숫자, 특수문자 조합 8~20자리)" onInput={props.onInput} />
										<div className="inputFocus"></div>
									</div>
									<p className={props.validate['password'] ? 'txtChkOk' : 'txtChkWrong'}>{props.validateContent['password']}</p>
								</div>
								<h4>신규 비밀번호 확인</h4>
								<div className="box">
									<div className="inputSt1">
										<InputDelButton type="password" value={props.values.passwordCheck} name="passwordCheck" className="clearInput" placeholder="신규 비밀번호 재입력(영문, 숫자, 특수문자 조합 8~20자리)" onInput={props.onInput} />
										<div className="inputFocus"></div>
									</div>
									<p className={props.validate['passwordCheck'] ? 'txtChkOk' : 'txtChkWrong'}>{props.validateContent['passwordCheck']}</p>
								</div>
								<div className="btnCenter">
									{onClass !== '' && props.validate['password'] === true && props.validate['passwordCheck'] === true ?
										<a href="#this" className={`btnTy2 ${onClass}`} onClick={props.onSubmit}>저장</a> : <a href="#this" className={`btnTy2`}>저장</a>
									}
								</div>
							</div>
						</div>
						<a href="#this" className="txtHide btnLayerClose1 layerPwChange_close" onClick={props.onClear}>닫기</a>
					</div>
				</div>
			</div>
			<div className="popup_background" onClick={props.onClear} style={style_popup_background}></div>
		</>
	) : null;
}

function MobileForm(props) {
	const onClassSubmit = props.values.sendedMobile && props.values.confirmNo ? 'on' : '';
	const onClassSendmsg = props.values.mobile ? 'on' : '';
	return props.nowMod[1] ? (
		<>
			<div id="layerPhoneChange_wrapper" className="popup_wrapper popup_wrapper_visible" style={style_popup_wrapper}>
				<div id="layerPhoneChange" className="popup_content" style={style_popup_content}>
					<div className="layerBox">
						<div className="layerHd">
							<h3>휴대폰 번호 변경</h3>
						</div>
						<div className="layerCnt">
							<div className="layerCom">
								<h4>휴대폰 번호</h4>
								<div className="box telLi">
									<div className="inputSt1">
										<InputDelButton type="tel" value={props.values.mobile} name="mobile" className="clearInput" placeholder="휴대폰 번호 입력" onInput={props.onInput} />
										<div className="inputFocus"></div>
									</div>
									<a href="#this" className={`btnTy4 ${onClassSendmsg}`} onClick={props.onSend}>{!props.values.sendedMobile ? "발송" : "재발송"}</a>
								</div>
								<h4>인증번호</h4>
								<div className="box numLi">
									<div className="inputSt1">
										<InputDelButton type="tel" value={props.values.confirmNo} className="clearInput" name="confirmNo" placeholder="4자리" onInput={props.onInput} maxLength={4} />
										<div className="inputFocus"></div>
										{props.values.sendedMobile && <Timer onTimeout={props.onTimeout} startTime={props.msgSendDate} />}
									</div>
								</div>
								<div className="btnCenter">
									{props.msgTimeout === false ?
										<a href="#this" className={`btnTy2 ${onClassSubmit}`} onClick={props.onSubmit}>확인</a>
										:
										<a href="#this" className={`btnTy2`} onClick={(e) => e.preventDefault()}>확인</a>
									}
								</div>
							</div>
						</div>
						<a href="#this" className="txtHide btnLayerClose1 layerPhoneChange_close" onClick={props.onClear}>닫기</a>
					</div>
				</div>
			</div>
			<div className="popup_background" onClick={props.onClear} style={style_popup_background}></div>
		</>
	) : null;
}

function NicknameForm(props) {
	const onClass = props.value.nickname ? ' on' : '';
	return props.nowMod[2] ? (
		<>
			<div id="layerNameChange_wrapper" className="popup_wrapper popup_wrapper_visible" style={style_popup_wrapper}>
				<div id="layerNameChange" className="popup_content" style={style_popup_content}>
					<div className="layerBox">
						<div className="layerHd">
							<h3>닉네임 변경</h3>
						</div>
						<div className="layerCnt">
							<div className="layerCom">
								<h4>닉네임</h4>
								<div className="box">
									<div className="inputSt1">
										<InputDelButton type="text" value={props.value.nickname} name="nickname" className="clearInput" placeholder="닉네임 입력(최대 10자 입력가능)" onInput={props.onInput} maxLength="10" />
										<div className="inputFocus"></div>
									</div>
								</div>
								<div className="btnCenter">
									<a href="#this" className={`btnTy2 ${onClass}`} onClick={props.onSubmit}>저장</a>
								</div>
							</div>
						</div>
						<a href="#this" className="txtHide btnLayerClose1 layerNameChange_close" onClick={props.onClear}>닫기</a>
					</div>
				</div>
			</div>
			<div className="popup_background" onClick={props.onClear} style={style_popup_background}></div>
		</>
	) : null;
}

function SexForm(props) {
	//const onClass = props.value.sex ? ' on' : '';
	const checkedM = props.value.sex === 1 || (props.value.sex === '' && (props.memberInfo.sex === '' || props.memberInfo.sex === 0)) || (props.value.sex !== 2 && props.memberInfo.sex === 1) ? true : false;
	const checkedW = props.value.sex === 2 || (props.value.sex !== 1 && props.memberInfo.sex === 2) ? true : false;
	return props.nowMod[3] ? (
		<>
			<div id="layerSexChange_wrapper" className="popup_wrapper popup_wrapper_visible" style={style_popup_wrapper}>
				<div id="layerSexChange" className="popup_content" style={style_popup_content}>
					<div className="layerBox">
						<div className="layerHd">
							<h3>성별 변경</h3>
						</div>
						<div className="layerCnt">
							<div className="layerCom">
								<h4>성별</h4>
								<div className="box">
									<label className="rdoBox1">
										<input type="radio" name="gender" value="1" checked={checkedM} onChange={props.onChg} />
										<span className="icon"></span>
										<span className="text">남자</span>
									</label>
									<label className="rdoBox1">
										<input type="radio" name="gender" value="2" checked={checkedW} onChange={props.onChg} />
										<span className="icon"></span>
										<span className="text">여자</span>
									</label>
								</div>
								<div className="btnCenter">
									<a href="#this" className={`btnTy2 on`} onClick={props.onSubmit}>저장</a>
								</div>
							</div>
						</div>
						<a href="#this" className="txtHide btnLayerClose1 layerSexChange_close" onClick={props.onClear}>닫기</a>
					</div>
				</div>
			</div>
			<div className="popup_background" onClick={props.onClear} style={style_popup_background}></div>
		</>
	) : null;
}
export default withRouter(withApolloClient(SetInfo))