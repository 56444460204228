import React from "react";
import { getLocation, createKakaoMap, gqlErrorHandler, setLocAddressMain, isLogin } from './Library'
import { loader } from 'graphql.macro'
import { withApolloClient } from './ApolloClient'
import Modal from './CustomModal'
import { withRouter } from "react-router-dom"

const addAddressGql = loader('../gql/add_address.gql')

class MapAddress extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			detailAddr: '',
			addr: null,
			roadAddr: null,
			invalidAddr: false,
			showInputDetail: false,
		}
		this.geo = null // 현위치
		this.textInput = React.createRef();
	}
	componentDidMount() {
		let geo = JSON.parse(localStorage.getItem('geoLatLng'))
		localStorage.removeItem('geoLatLng')

		if (!geo) {/* '현재위치'로가 아니고, 검색을 통해서 혹은 브라우저에 URL을 넣어서 온 경우 */
			const pos = getLocation(null, true)/* 맵을 일단 기본주소로 세팅한다 */
			geo = pos.coords/* 맵을 일단 기본주소(서초구 서초동)로 세팅한다 */
		}
		this.geo = {
			coords: {
				lat: geo.latitude,
				lng: geo.longitude,
			}
		}
		this.makeMap()
	}
	handleInputDetailAddr = (e) => {
		e.preventDefault();
		if (!this.state.roadAddr) {
			Modal.warning({ content: <>도로명 주소가 없습니다. <br />다른 주소로 설정해주세요.</> })
			return
		}
		this.setState({ showInputDetail: true })
	}
	makeMap = () => {
		createKakaoMap({ // 카카오맵 생성
			id: 'kakao-map',
			lat: this.geo.coords.lat,
			lng: this.geo.coords.lng,
			level: 4
		}, (map) => { // 마커표시
			//map.setZoomable(false)
			const markerPosition = new window.kakao.maps.LatLng(this.geo.coords.lat, this.geo.coords.lng)
			const markerImage = new window.kakao.maps.MarkerImage('/img/reImg/common/ico_map_pin2.png', new window.kakao.maps.Size(26, 33))
			const marker = new window.kakao.maps.Marker({
				map,
				draggable: false,
				position: markerPosition,
				image: markerImage,
			})

			this.map = map
			this.marker = marker

			this.geocoder = new window.kakao.maps.services.Geocoder();
			if (localStorage.getItem('mapAddr')) {
				const mapAddr = JSON.parse(localStorage.getItem('mapAddr'))
				localStorage.removeItem('mapAddr')
				/**
				 * mapAddr의 address는 daum api에서 검색키워드에 따라 지번주소 or 도로명주소 랜덤인듯. 
				 * 		'구로동'으로만 검색하면 도로명주소로되고, 완전한지번주소'서울 구로구 구로동 703-3'전체로검색하면 지번주소로됨.
				 * 		완전한도로명주소'서울 구로구 가마산로 77'로 검색해도 도로명주소로 됨, 도로명주소로 되는 경향이 높은듯.
				 * addressSearch API는 array로 주소여러개를 가져오는데 지번주소로 해야 0번을 기대했던 주소로 받을수 있는듯 하다.
				 * 		그러므로 위 address대신 jibunAddress를 써야하는데 일반 적인 경우는 항상 값이 있으나, 
				 * 		'서울 광진구 능동로 19'같이 하나의 도로명주소에 여러 지번주소가 있는 경우 특정 지번주소클릭 하지 않고 도로명주소만 클릭하면
				 * 		jibunAddress은 빈값으로 된다. 이런경우는 address로 일단 보여주고 문의오면 특정 지번주소클릭을 선택해라고 안내하면 될듯.
				 * 분석사유: http://redmine.koapp.com/issues/1934
				 * 		'강원도청'으로 검색하면 address가 도로명주소인 '강원 춘천시 중앙로 1' 로 되고 이 도로명주소로
				 * 		addressSearch로 검색진행하면 결과주소를 두개 주는데 0번은 원하는주소가 아닌'강원 춘천시 중앙로2가 1'로 되고,
				 * 		1번이 원하는주소였던것임.
				 */
				this.geocoder.addressSearch(mapAddr.jibunAddress || mapAddr.address, (result, status) => { // 비동기임, daumAPI에서 받은 주소로 map/maker의 좌표를 구해준다.
					if (status === window.kakao.maps.services.Status.OK) {
						const coords = new window.kakao.maps.LatLng(result[0].y, result[0].x)
						this.map.setCenter(coords)
						this.marker.setPosition(coords)
						document.getElementById('kakao-map').style.visibility = 'visible'
						this.updateAddress(result); //주소를 state에 세팅, daumAPI로 검색해서 들어온 경우 주소검색횟수를 줄이기 위해 바로 result로 세팅하게한다.
					} else {
						console.log(status);
					}
				})
			} else {
				document.getElementById('kakao-map').style.visibility = 'visible'
				this.updateAddress();
			}
			// 지도이동 start
			window.kakao.maps.event.addListener(map, 'dragstart', () => {
				this.textInput.current.blur()
				this.setState({ addr: null, roadAddr: null, invalidAddr: false, showInputDetail: false })
			})
			// 지도이동 중
			window.kakao.maps.event.addListener(map, 'drag', () => this.marker.setPosition(this.map.getCenter()));
			// 지도이동 end
			window.kakao.maps.event.addListener(map, 'dragend', () => this.updateAddress())
			// 줌이 변경되었을 때
			window.kakao.maps.event.addListener(map, 'zoom_changed', () => {
				this.marker.setPosition(this.map.getCenter())
				this.updateAddress()
			});
		})
	}
	getAddress = async () => { //marker의 좌표로 주소를 구해서 리턴한다.
		const coords = this.marker.getPosition()
		try {
			const address = await new Promise((resolve, reject) => {
				this.geocoder.coord2Address(coords.getLng(), coords.getLat(), (result, status) => {// 경위도 to 지번주소
					if (status === window.kakao.maps.services.Status.OK) {
						resolve(result)
					} else {
						reject(new Error(`coord2Address status: ${status}`))
					}
				})
			})

			const roadAddr = await (address[0].road_address
				? address // 지번주소에 road_address가 있으면 지번주소객체를 사용한다.
				: new Promise((resolve, reject) => { // 없으면 지번주소로 도로명주소를 구한다(주소가 다소 차이날 수 있음)
					this.geocoder.addressSearch(address[0].address.address_name, (result, status) => {//지번주소 to 도로명주소
						if (status === window.kakao.maps.services.Status.OK) {
							resolve(result)
						} else {
							reject(new Error(`addressSearch status: ${status}`))
						}
					})
				}))
			return roadAddr
		} catch (err) {
			console.log(err)
			return null
		}
	}
	updateAddress = async (param_roadAddr) => { //주소를 state에 세팅해준다.
		const roadAddr = await (param_roadAddr ? param_roadAddr : this.getAddress())
		//console.log(roadAddr)
		if (roadAddr) {
			let ra_name = null
			if (roadAddr[0].road_address) { //빌딩명 있으면 도로명주소에 붙여주기
				ra_name = roadAddr[0].road_address.address_name
				ra_name = roadAddr[0].road_address.building_name ? ra_name + ' (' + roadAddr[0].road_address.building_name + ')' : ra_name
			}
			this.setState({
				addr: roadAddr[0].address.address_name,
				roadAddr: ra_name,
				invalidAddr: false,
			})
		} else {
			this.setState({
				addr: null,
				readAddr: null,
				invalidAddr: true,
			})
		}
	}
	handleReturn = (e) => { // 돌아오기
		e.preventDefault();
		this.map.panTo(new window.kakao.maps.LatLng(this.geo.coords.lat, this.geo.coords.lng))
		this.marker.setPosition(new window.kakao.maps.LatLng(this.geo.coords.lat, this.geo.coords.lng))
		this.updateAddress();
	}
	handleSetAddr = (e) => {
		e.preventDefault()
		if (!this.state.addr) {
			Modal.warning({ content: <>지번 주소가 없습니다. <br />다른 주소로 설정해주세요.</> })
			return
		}
		if (!this.state.roadAddr) {
			Modal.warning({ content: <>도로명 주소가 없습니다. <br />다른 주소로 설정해주세요.</> })
			return
		}
		if (this.state.detailAddr === '') {
			Modal.warning({
				content: <>상세 주소를 입력해 주세요.</>,
				onOk: () => {
					setTimeout(() => {
						this.textInput.current.focus()
					}, 250);
				}
			})
			return
		}
		const coords = this.marker.getPosition()
		this.geocoder.coord2RegionCode(coords.getLng(), coords.getLat(), (result, status) => {
			if (status === window.kakao.maps.services.Status.OK) {
				const region = result[0].code
				const lat = coords.getLat()
				const lng = coords.getLng()
				const sido = result[0].region_1depth_name || result[1].region_1depth_name
				const gugun = result[0].region_2depth_name || result[1].region_2depth_name
				const dong = result[0].region_3depth_name || result[1].region_3depth_name
				let hCode = null;
				let bCode = null;

				for (const res of result) {
					res.region_type === 'B' && (bCode = res.code)
					res.region_type === 'H' && (hCode = res.code)
				}

				this.saveMyAddr({ ...this.state, region, lat, lng, sido, gugun, dong, bCode, hCode })
			} else {
				console.log(status);
			}
		})
	}
	saveMyAddr = ({ detailAddr, addr, roadAddr, region, lat, lng, sido, gugun, dong, bCode, hCode }) => {
		const values = {
			oldAddr1: addr,
			oldAddr2: detailAddr,
			addr1: roadAddr,
			addr2: detailAddr,
			lat,
			lng,
		}
		/* 로컬스테이트 값 */
		const locAddr = {
			...values,
			sido,
			gugun,
			dong,
			hCode,
			bCode,
		}
		/* 서버로 뮤테이션할 값 */
		const servAddr = {
			...values,
			isMain: true,
			region,
		}
		const prms = isLogin()
			? this.props.client
				.mutate({
					mutation: addAddressGql,
					variables: servAddr,
				})
			: Promise.resolve()
		prms
			.then(() => {
				setLocAddressMain(locAddr)
				this.props.history.replace('/')
			})
			.catch((err) => gqlErrorHandler(err));
	}
	render() {
		return (
			<div id="reWrap">
				<div id="header" className="subHeader">
					<div className="header">
						<a href="#this" className="txtHide btnHback" onClick={(e) => { e.preventDefault(); this.props.history.goBack(); }}>뒤로가기</a>
						<h1>위치 지정</h1>
					</div>
				</div>

				<div id="container">
					<div id="contents" className="subCnts8">
						<div className="myLocation">
							<div className="mapView">
								<div id='kakao-map' style={{ width: '100%', height: '100%', visibility: 'hidden' }}></div>
								<a href="#this" className="txtHide btnMyLocation" onClick={this.handleReturn}>내위치지정</a>
							</div>
							<div className="mapAdrSch">
								{this.state.addr
									? <p>
										{this.state.addr}
										{this.state.roadAddr && <span>[도로명] {this.state.roadAddr}</span>}
									</p>
									: <p className="move">
										{!this.state.invalidAddr ? '위치 이동 중' : '잘못된 주소입니다. 다시 입력해주세요.'}
									</p>
								}
								<div className="adrBt">
									<div className="inputSt1">
										<input type="text" ref={this.textInput} value={this.state.detailAddr} onChange={(e) => this.setState({ detailAddr: e.target.value })} placeholder="상세 주소를 입력하세요.(건물명, 동/호수)" />
										<div className="inputFocus"></div>
									</div>
									<div className="btnCenter">
										<a href="#this" className={`btnTy2 ${this.state.addr && this.state.roadAddr && this.state.detailAddr ? 'on' : ''}`} onClick={this.handleSetAddr}>이 위치로 지정</a>
									</div>
									{/* 버튼의 on 클래스주기랑 핸들링 메서드를 지번,도로명,상세 주소가 있을 때만 저장, 없으면 알럿 띄우기 처리하면 끝 */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default withRouter(withApolloClient(MapAddress))