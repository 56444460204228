import React from "react";
import { withRouter } from "react-router-dom";
import BottomScrollListener from 'react-bottom-scroll-listener';
import { loader } from 'graphql.macro';
import { gqlErrorHandler } from './Library';
import { Query } from '@apollo/client/react/components';
import { withApolloClient } from './ApolloClient';

const PUSH_QUERY = loader('../gql/push_list.gql');
const RECENT_PUSH_QUERY = loader('../gql/recent_push.gql');
/*
const category_list = {
	EVENT: '이벤트',
	NOTICE: '공지',
	UPDATE: '업데이트',
	WORKING : '주문처리 중',
	PAY: '주문접수',
	FINISH: '주문처리 완료',
	SHIP_FINISH: '배달완료',
	CANCEL : '주문취소',
	QNA: '1:1 문의'
};
*/

class Message extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			acitveKey: null,
		}
		this.messageTimeHandler = this.messageTimeHandler.bind(this);
		this.activeDataHandler = this.activeDataHandler.bind(this);
		this.setRecentPush = this.setRecentPush.bind(this);
		this.handleFetchMore = this.handleFetchMore.bind(this);
		this.doFetchMore = React.createRef(true);
	}
	componentDidMount() {
		window.scrollTo(0, 0);
		this.setRecentPush();
	}
	setRecentPush() {
		this.props.client.query({
			query: RECENT_PUSH_QUERY,
			variables: { gongId: process.env.REACT_APP_SERVICE_GID }
		}).then((result) => {
			if (result.data.gongPushes.edges.length === 1) {
				let push = {
					id: result.data.gongPushes.edges[0].node.id,
					createdAt: result.data.gongPushes.edges[0].node.createdAt
				}
				localStorage.setItem('recentPush', JSON.stringify(push))
			}
		}).catch((err) => { gqlErrorHandler(err) });
	}
	activeDataHandler = (e, key) => {
		e.preventDefault();
		if (key === this.state.acitveKey) {
			this.setState({ acitveKey: null });
		} else {
			this.setState({ acitveKey: key });
		}
	}
	handleFetchMore = (fm, data) => {
		if (data.gongPushes.pageInfo.hasNextPage === false) return
		if (this.doFetchMore === false) return
		this.doFetchMore.current = false;

		fm({
			variables: {
				gongId: process.env.REACT_APP_SERVICE_GID,
				cursor: data.gongPushes.pageInfo.endCursor
			},
			updateQuery: (previousResult, { fetchMoreResult }) => {
				const newEdges = fetchMoreResult.gongPushes.edges;
				const pageInfo = fetchMoreResult.gongPushes.pageInfo;
				return newEdges.length
					? {
						gongPushes: {
							__typename: previousResult.gongPushes.__typename,
							edges: [...previousResult.gongPushes.edges, ...newEdges],
							pageInfo
						}
					}
					: previousResult;
			}
		}).then(() => this.doFetchMore.current = true);
	}
	messageTimeHandler = (createdAt) => {
		let msg = "";
		let createdAt_format = createdAt.replace(/-/g, '/');
		let create_timestamp = new Date(createdAt_format).getTime();
		let now_timestamp = new Date().getTime();
		let day_ago = (60 * 60 * 24);
		let week_ago = (60 * 60 * 24 * 7);
		let eight_day_ago = (60 * 60 * 24 * 8);
		let time_diff = parseInt(now_timestamp / 1000) - parseInt(create_timestamp / 1000);

		if (time_diff < day_ago) {
			if (time_diff < 3600) {
				msg = parseInt(time_diff / 60) + "분전";
			} else {
				msg = parseInt(time_diff / 3600) + "시간전";
			}
		} else if (time_diff < week_ago) {
			msg = parseInt(time_diff / day_ago) + "일전";
		} else if (time_diff < eight_day_ago) {
			msg = '일주일전';
		} else {
			msg = createdAt;
		}
		return msg;
	}

	render() {
		const { acitveKey } = this.state;
		const activeStyle = { display: 'block' }
		return (
			<div id="reWrap">
				<div id="header" className="subHeader">
					<div className="header">
						<a href="#this" onClick={(e) => { e.preventDefault(); this.props.history.goBack(); }} className="txtHide btnHback">뒤로가기</a>
						<h1>알림내역</h1>
					</div>
				</div>
				<div id="container">
					<div id="contents" className="subCnts10">
						<div className="noticeAlarm">
							<div className="topNoti">
								<p>최근 3달 내의 알림만 확인하실 수 있습니다.</p>
							</div>
							<Query query={PUSH_QUERY} variables={{ gongId: process.env.REACT_APP_SERVICE_GID }}>
								{({ loading, error, data, fetchMore }) => {
									if (loading) return null
									if (error) { gqlErrorHandler(error); return null }
									const entries = data.gongPushes.edges || [];
									if (entries.length === 0) { return <div style={{ height: 'calc(100vh - 106px)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p className="no-data">알림내역이 없습니다.</p></div> }
									return (
										<ul>
											<BottomScrollListener onBottom={() => this.handleFetchMore(fetchMore, data)}>
												{
													entries.map((v, k) => (
														<li key={k} className={acitveKey === k ? "on" : ""}>
															<a href="#this" className="alTit" onClick={(e) => this.activeDataHandler(e, k)}>
																{v.node.category && <h4>[{v.node.categoryName}]</h4>}
																<p>
																	{v.node.title}
																	{/* <em className="txtHide icoNew">New</em> */}
																</p>
																{(v.node.category === 'FINISH' || v.node.category === 'SHIP_FINISH') && <p><span className="txtSub1">*리뷰쓰기는 주문이후 3일동안만 가능합니다.</span></p>}
																<span>{this.messageTimeHandler(v.node.createdAt)}</span>
															</a>
															<div id={k} className="notiView" style={acitveKey === k ? activeStyle : { display: 'none' }}>
																<span style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: v.node.content }}></span>
															</div>
														</li>
													))
												}
											</BottomScrollListener>
										</ul>
									)
								}}
							</Query>
						</div>
					</div>
				</div>
			</div>
		)

	}
}

export default withApolloClient(withRouter(Message))