
import React from "react";
import { useReactiveVar } from '@apollo/client';
import { locAddressMainVar } from './ApolloClient'

/**
 * 로컬스테이트 locAddressMain 넘겨주기.
 * 기존 클래스형 컴포넌트에 이렇게 넘겨주는것이 코드 수정이 제일 적을 것 같음.
 */
export default InnerComponent => (props) => {
    const locAddressMain = useReactiveVar(locAddressMainVar)
    return <InnerComponent locAddressMain={locAddressMain} {...props} />
}