import React, { useEffect } from "react";
import { Switch, Route, useRouteMatch, NavLink } from 'react-router-dom';
import BottomScrollListener from 'react-bottom-scroll-listener';
import { loader } from 'graphql.macro';
import { gqlErrorHandler } from './Library';
import { Query } from '@apollo/client/react/components';
import dateFormat from 'dateformat';
import { chkImageUrl } from './Library';

const NOTICES_QUERY = loader('../gql/notice_list.gql');
const NOTICE_QUERY = loader('../gql/notice.gql');

const Notice = (props) => {
	useEffect(() => {
		window.scrollTo(0, 0)
	}, []);
	let match = useRouteMatch();
	return (
		<Switch>
			<Route exact path={`${match.path}`} component={NoticeList} />
			<Route path={`${match.path}/view/:id`} component={NoticeView} />
		</Switch>
	)
}

class NoticeView extends React.Component {
	render() {
		const { id } = this.props.match.params;
		return (
			<div id="reWrap">
				<div id="header" className="subHeader">
					<div className="header">
						<NavLink to="/mypage/notice" className="txtHide btnHback">뒤로가기 </NavLink>
						<h1>공지사항</h1>
					</div>
				</div>
				<div id="container">
					<div id="contents" className="subCnts10">
						<Query query={NOTICE_QUERY} variables={{ pk: id }}>
							{({ loading, error, data }) => {
								if (loading) return null
								if (error) { gqlErrorHandler(error); return null }
								if (data.gongNotice === '') {
									return <></>
								}
								return (
									<div className="noticeView">
										<div className="thDiv">
											<h4>[{data.gongNotice.categoryName}]</h4>
											<p>{data.gongNotice.title}</p>
											<span>{dateFormat(data.gongNotice.createdAt, "yyyy-mm-dd")}</span>
										</div>
										{
											data.gongNotice.imgUrl &&
											<div className="tdDiv">
												<div className="img">
													<img src={chkImageUrl(data.gongNotice.imgUrl)} alt="" />
												</div>
											</div>
										}
										<div className="tdDiv" style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: data.gongNotice.content }}></div>
									</div>
								)
							}}
						</Query>

					</div>
				</div>
			</div>
		)
	}

}

class NoticeList extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			title: '',
			category: ''
		}
		this.handleFetchMore = this.handleFetchMore.bind(this);
		this.doFetchMore = React.createRef(true);
	}

	handleFetchMore = (fm, data, searchVariables) => {
		if (data.gongNotices.pageInfo.hasNextPage === false) return
		if (this.doFetchMore === false) return
		this.doFetchMore.current = false;

		fm({
			variables: searchVariables,
			updateQuery: (previousResult, { fetchMoreResult }) => {
				const newEdges = fetchMoreResult.gongNotices.edges;
				const pageInfo = fetchMoreResult.gongNotices.pageInfo;
				return newEdges.length
					? {
						gongNotices: {
							__typename: previousResult.gongNotices.__typename,
							edges: [...previousResult.gongNotices.edges, ...newEdges],
							pageInfo
						}
					}
					: previousResult;
			}
		}).then(() => this.doFetchMore.current = true);
	}

	render() {
		let searchVariables = {
			gongId: process.env.REACT_APP_SERVICE_GID,
			category: this.state.category,
			title: this.state.title
		};

		const { category, title } = this.state

		return (
			<div id="reWrap">
				<div id="header" className="subHeader">
					<div className="header">
						<NavLink to="/mypage" className="txtHide btnHback">뒤로가기 </NavLink>
						<h1>공지사항</h1>
					</div>
				</div>
				<div id="container">
					<div id="contents" className="subCnts10">
						<div className="comSch1">
							<div className="tabSt2">
								<ul>
									<li><button className={category === "" ? "on" : ""} data-conts="all" onClick={(e) => this.setState({ category: '' })}>전체</button></li>
									<li><button className={category === "EVENT" ? "on" : ""} data-conts="event" onClick={(e) => this.setState({ category: 'EVENT' })}>이벤트</button></li>
									<li><button className={category === "NOTICE" ? "on" : ""} data-conts="notice" onClick={(e) => this.setState({ category: 'NOTICE' })}>공지</button></li>
									<li><button className={category === "UPDATE" ? "on" : ""} data-conts="update" onClick={(e) => this.setState({ category: 'UPDATE' })}>업데이트</button></li>
								</ul>
							</div>
							<div className="sch" style={{ paddingLeft: '16px' }}>
								{/* <div className="selectBox02">
									<select onChange={(e) => this.setState({ category: e.target.value })} value={category}>
										<option value="">전체</option>
										<option value="EVENT">이벤트</option>
										<option value="NOTICE">공지</option>
										<option value="UPDATE">업데이트</option>
									</select>
								</div> */}
								<input type="search" value={title} onChange={(e) => this.setState({ title: e.target.value })} placeholder="검색어를 입력해주세요" />
								<a href="#this" className="txtHide btnSch">검색</a>
							</div>
						</div>
						<hr className="hrBk" />

						<Query query={NOTICES_QUERY} variables={searchVariables} fetchPolicy="network-only">
							{({ loading, error, data, fetchMore }) => {
								if (loading) return null
								if (error) { gqlErrorHandler(error); return null }
								searchVariables.cursor = data.gongNotices.pageInfo.endCursor;
								const entries = data.gongNotices.edges || [];
								if (entries.length === 0) {
									if (searchVariables.title.trim() === "") {
										return (
											<div className="tabSectoin on">
												<div className="noticeAlarm list">
													<ul>
														<li class="noData">
															<p>작성된 공지사항이 없습니다.</p>
														</li>
													</ul>
												</div>
											</div>
										)
									} else {
										return (
											<div className="tabSectoin on">
												<div className="noticeAlarm list">
													<ul>
														<li class="noData">
															<p>‘{searchVariables.title}’에 대한 검색 결과가 없습니다.</p>
														</li>
													</ul>
												</div>
											</div>
										)
									}
								}
								return (
									<div className="tabSectoin on">
										<div className="noticeAlarm list">
											<BottomScrollListener onBottom={() => this.handleFetchMore(fetchMore, data, searchVariables)}>
												<ul>
													{
														entries.map((v, k) => (
															<li key={k}>
																<a href={"/mypage/notice/view/" + v.node.pk}>
																	<h4>[{v.node.categoryName}]</h4>
																	<p>
																		{v.node.title}
																		{messageTimeHandler(v.node.createdAt)}
																	</p>
																	<span>{v.node.createdAt}</span>
																</a>
															</li>
														))
													}
												</ul>
											</BottomScrollListener>
										</div>
									</div>
								)
							}}
						</Query>
					</div>
				</div>
			</div>
		)
	}
}


const messageTimeHandler = (createdAt) => {
	let createdAt_format = createdAt.replace(/-/g, '/');
	let create_timestamp = new Date(createdAt_format).getTime();
	let now_timestamp = new Date().getTime();
	let threedays = (60 * 60 * 24 * 3);
	let time_diff = parseInt(now_timestamp / 1000) - parseInt(create_timestamp / 1000);

	if (time_diff < threedays) {
		return (<em className="txtHide icoNew">New</em>);
	}
	return "";
}

export default Notice