import React from "react";
import { Affix } from 'antd'
import { createDaumPostcode } from './Library'
import { withRouter } from "react-router-dom";

class SearchAddress extends React.Component {
	constructor(props) {
		super(props);
		this.textInput = React.createRef();
		window.history.pushState(null, null, window.location.href)
	}
	componentDidMount() {
		createDaumPostcode({ id: 'daum-postcode-wrap' }, this.handlePickAddr, this.handleSearch)
		window.addEventListener('popstate', () => window.close()) // 다음api으로 인해 goback이 문제되므로, 새창으로 띄웠다가 '뒤로가기' 시 닫아버린다.
	}
	componentWillUnmount() {
		document.head.removeChild(document.getElementById('daum-postcode-script'))
	}
	handlePickAddr = (data) => {
		localStorage.setItem('mapAddr', JSON.stringify(data))
		window.opener.location.href = '/mypage/set-address/map'
		window.close()
	}
	handleSearch = (data) => {
		//console.log(data)
		window.history.pushState(data, null, window.location.href)
	}
	render() {
		return (
			<div id="wrap-sub" className="">
				<Affix offsetTop={0.1} >
					<header id="sub">
						<h1 style={{ fontWeight: 'bold' }}>내 주소 설정</h1>
						<a href="#this" className="close" onClick={(e) => { e.preventDefault(); this.props.history.goBack() }}><img src="/img/btn_close.png" alt="이미지" /></a>
					</header>
				</Affix>
				<div id="daum-postcode-wrap" style={{ width: '100%', height: '100%' }}></div>
				<div className="white-cont">
					<div className="pb30">
						<p className="btn-red btn-loca" onClick={(e) => this.props.handleLocationToMap(e, () => { window.opener.location.href = '/mypage/set-address/map'; window.close(); })}>
							<a href="#this">
								<span><img src="/img/ico_location.png" alt="이미지" /></span>
								<span>현재 위치로 주소 설정</span>
							</a>
						</p>
					</div>
				</div>
			</div>
		)
	}
}
export default withRouter(SearchAddress)