import React from "react";
import InputDelButton from "./InputDelButton";
import { withRouter } from "react-router-dom";
import AgreeTerms from "./AgreeTerms";
import axios from "axios";
import Modal from './CustomModal';
import { withApolloClient } from './ApolloClient';
import { loader } from 'graphql.macro';

const PUSH_SETTING_GQL = loader('../gql/push_setting.gql');

const startYear = 1920;
const endYear = parseInt(new Date().getFullYear());
const yearOptions = [];
const monthOptions = [];
const dayOptions = [];
for (var i = endYear; i >= startYear; i--) {
	yearOptions.push(i);
}
for (i = 1; i <= 12; i++) {
	monthOptions.push(i.toString().padStart(2, '0'));
}
for (i = 1; i <= 31; i++) {
	dayOptions.push(i.toString().padStart(2, '0'));
}

export default withApolloClient(withRouter(class Join extends React.Component {
	constructor(props) {
		super(props);

		// 휴대폰인증을 거치고 오지 않았으면 휴대폰인증으로
		if (!localStorage.confirmed_mobile) this.props.history.push('/mobile-confirm/join');

		this.state = {
			isAgree: false,
			isAddAgree: false,
			isBirthMod: false,
			values: {
				email: '',
				password: '',
				passwordCheck: '',
				nickname: '',
				sex: '',
				birth: '',
				year: '',
				month: '',
				day: ''
			},
			validate: {
				email: false,
				password: false,
				passwordCheck: false,
				nickname: true
			},
			validateContent: {
				email: '',
				password: '',
				passwordCheck: '',
				nickname: '',
			}
		}

		this.handleAgree = this.handleAgree.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.validateMessage = this.validateMessage.bind(this);
		this.isValidate = this.isValidate.bind(this);
		this.handleSex = this.handleSex.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleBirthChange = this.handleBirthChange.bind(this);
		this.handleAgreeBack = this.handleAgreeBack.bind(this);
	}
	componentDidMount() {
		window.scrollTo(0, 0);
	}
	handleAgree(isAddAgree) {
		this.setState({ isAgree: true, isAddAgree: isAddAgree });
	}

	handleInput(mode, value) {
		let values = this.state.values;
		let validate = this.state.validate;
		let validateContent = this.state.validateContent;

		Object.keys(values).map((key) => key === mode ? values[key] = value : null);

		// 입력값 검증
		switch (mode) {
			case 'email':
				const regEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

				if (value) {
					if (regEmail.test(value)) {
						axios
							.post(`${process.env.REACT_APP_SERVER_REST}/members/can/email?email=${value}`)
							.then(res => {
								if (res.data.result) {
									validate.email = true;
									validateContent.email = '사용 가능한 이메일입니다.';
								} else {
									validate.email = false;
									validateContent.email = '이미 사용중인 이메일입니다.';
								}
								this.setState({});
							})
							.catch(res => {
								validate.email = false;
								validateContent.email = '이메일 중복확인 오류입니다. 다시 입력해주세요.';
							});
					} else {
						validate.email = false;
						validateContent.email = '올바른 이메일 주소를 입력해주세요.';
					}
				} else {
					validate.email = false;
					validateContent.email = null;
				}
				break;
			case 'password':
				const regPassword = /^(?!((?:[A-Za-z]+)|(?:[0-9]+)|(?:[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]+))$)[A-Za-z\d!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]{8,20}$/;

				if (value) {
					if (regPassword.test(value)) {
						validate.password = true;
						validateContent.password = '사용 가능한 비밀번호입니다.';
					} else {
						validate.password = false;
						validateContent.password = '8~20자의 영문, 숫자, 특수문자 2종류 이상 입력해 주세요.';
					}
					//비밀번호 체크
					if (value === values.passwordCheck) {
						validate.passwordCheck = true;
						validateContent.passwordCheck = '비밀번호가 일치합니다.';
					} else {
						validate.passwordCheck = false;
						validateContent.passwordCheck = '비밀번호가 일치하지 않습니다.';
					}
				} else {
					validate.password = false;
					validateContent.password = null;

					if (values.passwordCheck) {
						validate.passwordCheck = false;
						validateContent.passwordCheck = '비밀번호가 일치하지 않습니다.';
					}
				}
				break;
			case 'passwordCheck':
				if (value) {
					if (value === values.password) {
						validate.passwordCheck = true;
						validateContent.passwordCheck = '비밀번호가 일치합니다.';
					} else {
						validate.passwordCheck = false;
						validateContent.passwordCheck = '비밀번호가 일치하지 않습니다.';
					}
				} else {
					validate.passwordCheck = false;
					validateContent.passwordCheck = null;
				}
				break;
			case 'nickname':
				if (value) {
					if (value.length < 11) {
						axios
							.post(`${process.env.REACT_APP_SERVER_REST}/members/can/nick?nick=${value}`)
							.then(res => {
								if (res.data.result) {
									validate.nickname = true;
									validateContent.nickname = '사용 가능한 닉네임입니다.';
								} else {
									validate.nickname = false;
									validateContent.nickname = '이미 사용중인 닉네임입니다.';
								}
								this.setState({});
							})
							.catch(res => {
								validate.nickname = false;
								validateContent.nickname = '닉네임 중복확인 오류입니다. 다시 입력해주세요.';
							});
					} else {
						validate.nickname = false;
						validateContent.nickname = '10자 이내로 입력해주세요.';
					}
				} else {
					validate.nickname = true;
					validateContent.nickname = null;
				}
				break;
			default:
				return;
		}

		this.setState({});
	}

	handleSex(e) {
		let values = this.state.values;
		values.sex = e.target.value;

		this.setState({});
	}

	isValidate() {
		let isValidate = true;
		const validate = this.state.validate;
		Object.keys(validate).map((key) => isValidate = isValidate && validate[key]);

		return isValidate;
	}

	validateMessage(name) {
		let message = null;
		const validate = this.state.validate;
		const validateContent = this.state.validateContent;

		if (validateContent[name]) {
			let className = validate[name] ? 'txtChkOk' : 'txtChkWrong';
			message = <p className={className}>{validateContent[name]}</p>;
		}

		return (message);
	}

	handleBirthChange(e) {
		let values = this.state.values;
		Object.keys(values).map((key) => key === e.target.name ? values[key] = e.target.value : '');
		if (this.state.values.year && this.state.values.month && this.state.values.day) {
			values.birth = this.state.values.year + this.state.values.month + this.state.values.day;
		}
		this.setState({ values: values });
	}

	handleSubmit(e) {
		e.preventDefault();
		if (this.state.values.birth === '' && (this.state.values.year || this.state.values.month || this.state.values.day)) {
			Modal.warning({ content: '날짜를 선택해주세요.' });
			return false;
		} else if (this.isValidate()) {
			const agree = this.state.isAddAgree ? 'y' : 'n';
			axios
				.post(`${process.env.REACT_APP_SERVER_REST}/v2/api/member/join`,
					`email=${this.state.values.email}&pw=${encodeURIComponent(this.state.values.password)}&nick=${this.state.values.nickname}&sex=${this.state.values.sex}&birth=${this.state.values.birth}&agree=${agree}`,
					{ headers: { 'Authorization': 'Bearer ' + localStorage.access_token } }
				)
				.then(res => {
					if (res.data.result) {
						axios.get(`${process.env.REACT_APP_SERVER_REST}/members/info`,
							{ headers: { Authorization: 'Bearer ' + localStorage.access_token } })
							.then(res => {
								if (res.data.result) {
									localStorage.member_id = res.data.id;
									localStorage.member_nick = res.data.nick;
									localStorage.member_email = res.data.email;
									localStorage.member_mobile = res.data.phone;

									if (res.data.id) {
										/* global AppScheme */
										typeof AppScheme !== "undefined" && AppScheme(`sp00order${process.env.REACT_APP_SERVICE_GID}://member?id=${res.data.id}`);
									}

									// 기본 앱회원 등록
									this.props.client.query({
										query: PUSH_SETTING_GQL,
										variables: { gongId: process.env.REACT_APP_SERVICE_GID },
										fetchPolicy: 'network-only',
									});
								}
								Modal.success({
									content: '회원가입이 완료되었습니다.',
									onOk: () => { window.location.replace('/') }
								});
							})
							.catch(res => { Modal.error({ content: '로그인 처리중 오류가 발생하였습니다.' }); });
					} else {
						Modal.warning({ content: res.data.msg });
					}
				})
				.catch(res => {
					Modal.error({ content: '회원가입 처리중 오류가 발생하였습니다.' });
				});
		}
	}
	handleAgreeBack(e) {
		e.preventDefault();
		window.history.go(-2);
	}

	render() {
		if (!this.state.isAgree) {
			return (<AgreeTerms onAgree={this.handleAgree} onBack={this.handleAgreeBack} />)
		} else {
			const onBtnClass = this.isValidate() ? 'btn btn-join on' : 'btn btn-join';
			return (
				<>
					<div id="reWrap">
						<div id="header" className="subHeader">
							<div className="header">
								<a href="#this" onClick={(e) => { e.preventDefault(); this.setState({ isAgree: false }); }} className="txtHide btnHback">뒤로가기</a>
								<h1>회원가입</h1>
							</div>
						</div>
						<div id="container">
							<div id="contents" className="subCnts">
								<div className="formWrite">
									<div className="box">
										<h4><em>*</em> 이메일</h4>
										<div className="inputSt1">
											<InputDelButton type="email" name="email" className="clearInput" placeholder="아이디(이메일) 입력" onInput={this.handleInput} />
											<div className="inputFocus"></div>
										</div>
										{this.validateMessage('email')}
									</div>
									<div className="box">
										<h4><em>*</em> 비밀번호</h4>
										<div className="inputSt1">
											<InputDelButton type="password" name="password" className="clearInput" placeholder="비밀번호 입력(영문, 숫자, 특수문자 조합 8~20자리)" onInput={this.handleInput} />
											<div className="inputFocus"></div>
										</div>
										{this.validateMessage('password')}
									</div>
									<div className="box">
										<h4><em>*</em> 비밀번호 확인</h4>
										<div className="inputSt1">
											<InputDelButton type="password" name="passwordCheck" className="clearInput" placeholder="비밀번호 재입력(영문, 숫자, 특수문자 조합 8~20자리)" onInput={this.handleInput} />
											<div className="inputFocus"></div>
										</div>
										{this.validateMessage('passwordCheck')}
									</div>
									<div className="box">
										<h4> 닉네임</h4>
										<div className="inputSt1">
											<InputDelButton type="text" name="nickname" className="clearInput" maxlength="10" placeholder="닉네임 입력(최대 10자 입력가능)" onInput={this.handleInput} />
											<div className="inputFocus"></div>
										</div>
										{this.validateMessage('nickname')}
									</div>
									<div className="box">
										<h4>성별</h4>
										<label className="rdoBox1">
											<input type="radio" name="gender" value="1" onChange={this.handleSex} />
											<span className="icon"></span>
											<span className="text">남자</span>
										</label>
										<label className="rdoBox1">
											<input type="radio" name="gender" value="2" onChange={this.handleSex} />
											<span className="icon"></span>
											<span className="text">여자</span>
										</label>
									</div>
									<div className="box">
										<h4>생년월일</h4>
										<div className="birth">
											<div className="selectBox01">
												<select name="year" value={this.state.values.year} onChange={this.handleBirthChange} className="select-box sel-num">
													<option value="">년</option>
													{yearOptions.map((value) => <option key={value} value={value}>{value}</option>)}
												</select>
											</div>
											<div className="selectBox01">
												<select name="month" value={this.state.values.month} onChange={this.handleBirthChange} className="select-box sel-num">
													<option value="">월</option>
													{monthOptions.map((value) => <option key={value} value={value}>{value}</option>)}
												</select>
											</div>
											<div className="selectBox01">
												<select name="day" value={this.state.values.day} onChange={this.handleBirthChange} className="select-box sel-num">
													<option value="">일</option>
													{dayOptions.map((value) => <option key={value} value={value}>{value}</option>)}
												</select>
											</div>
										</div>
									</div>
									<div className="btnCenter">
										<a href="#this" onClick={this.handleSubmit} className={`btnTy2 ${onBtnClass}`}>가입</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>

			);
		}
	}
}));