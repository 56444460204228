
import React from "react";
import { NavLink } from "react-router-dom";
import { isLogin, gqlErrorHandler } from './Library';
import { loader } from 'graphql.macro';
import { withApolloClient } from './ApolloClient';

const RECENT_PUSH_QUERY = loader('../gql/recent_push.gql');

 class PushMark extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			onClass: ''
		};
		this.hadleCheckThreeDays = this.hadleCheckThreeDays.bind(this);		
	}
	componentDidMount() {
		if (isLogin()) {
			this.props.client.query({
				query: RECENT_PUSH_QUERY,
				variables: { gongId: process.env.REACT_APP_SERVICE_GID }
			}).then((result) => {
				if (result.data.gongPushes.edges.length === 1) {
					let recentPush = {
						id: result.data.gongPushes.edges[0].node.id,
						createdAt: result.data.gongPushes.edges[0].node.createdAt
					}
					let localPush = JSON.parse(localStorage.getItem('recentPush'));
					let msg = '';
					if (localPush && recentPush.id !== localPush.id && this.hadleCheckThreeDays(recentPush.createdAt)) {
						//최근푸쉬, 로컬에 저장된 푸쉬가 모두있고, 푸쉬 아이디가 서로 다르며, 최근푸쉬가 3일이내
						msg = 'on';
					} else if(recentPush.id && !localPush && this.hadleCheckThreeDays(recentPush.createdAt)) {
						//최근 푸쉬만 있고, 그게 3일이내
						msg = 'on';
					}
					this.setState({ onClass: msg });
				}
			}).catch((err) => { gqlErrorHandler(err) });
		}
	}
	hadleCheckThreeDays(createdAt) {
		let createdAt_format = createdAt.replace(/-/g, '/');
		let create_timestamp = new Date(createdAt_format).getTime();
		let now_timestamp = new Date().getTime();
		let three_day_ago = (60 * 60 * 24 * 3);
		let time_diff = parseInt(now_timestamp / 1000) - parseInt(create_timestamp / 1000);
		if (time_diff < three_day_ago) {
			return true;
		} 			
		return false;
	}
	render() {		
		if (isLogin()) {
			const {onClass} = this.state;
			return (
			<NavLink to="/mypage/message" className={`txtHide btnAlarm ${onClass}`}>알람</NavLink>
			)
		} else {
			return (
				<NavLink to="/mypage/message" className="txtHide btnAlarm">알람</NavLink>
			);
		}

	}
}
export default withApolloClient(PushMark);